import { default as dashboardhKii8fXWAxMeta } from "/usr/src/app/pages/dashboard.vue?macro=true";
import { default as _91identifier_937XfjtpS143Meta } from "/usr/src/app/pages/database/[identifier].vue?macro=true";
import { default as _403nSVv3KgT0OMeta } from "/usr/src/app/pages/error/403.vue?macro=true";
import { default as _404WivUUbKe5wMeta } from "/usr/src/app/pages/error/404.vue?macro=true";
import { default as _500Wi55kHlytRMeta } from "/usr/src/app/pages/error/500.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91identifier_93QuSrBUP6SFMeta } from "/usr/src/app/pages/knowledge-base/articles/[identifier].vue?macro=true";
import { default as indexPK4J6TyKtnMeta } from "/usr/src/app/pages/knowledge-base/articles/index.vue?macro=true";
import { default as index9UaQHjx8Q7Meta } from "/usr/src/app/pages/knowledge-base/index.vue?macro=true";
import { default as indexLTysiFnUI5Meta } from "/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue?macro=true";
import { default as indexZZhFjk9ZfGMeta } from "/usr/src/app/pages/knowledge-base/tags/index.vue?macro=true";
import { default as indexl38GLAvKVHMeta } from "/usr/src/app/pages/national-reports/index.vue?macro=true";
import { default as indexsV0YGwxtHhMeta } from "/usr/src/app/pages/national-reports/nr7/index.vue?macro=true";
import { default as indexqAaag6k3KSMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue?macro=true";
import { default as indicator_45datar4FCObtcGyMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue?macro=true";
import { default as overviewf9kPW4zRjgMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue?macro=true";
import { default as section_45annexJuwaxMeKOoMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue?macro=true";
import { default as section_45IOUxLtY92lYMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue?macro=true";
import { default as section_45IIvPfvYsvtFnMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue?macro=true";
import { default as section_45IIIKizDNnkcCkMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue?macro=true";
import { default as section_45IVtc4zFIuIXIMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue?macro=true";
import { default as section_45VCVNGerfoo0Meta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue?macro=true";
import { default as section_45VIgrD5vjTm7tMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue?macro=true";
import { default as indexAMuO34C4sOMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/index.vue?macro=true";
import { default as index6umqEWvPtlMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue?macro=true";
import { default as analyzer1ndP9iNI4QMeta } from "/usr/src/app/pages/national-targets/analyzer.vue?macro=true";
import { default as indexb9IE1lLRN3Meta } from "/usr/src/app/pages/national-targets/index.vue?macro=true";
import { default as indexJ6mjSzSz5lMeta } from "/usr/src/app/pages/national-targets/my-country/index.vue?macro=true";
import { default as editMqCXwCUPWOMeta } from "/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue?macro=true";
import { default as index8uiIbni5p0Meta } from "/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue?macro=true";
import { default as viewmYVEBfT2oEMeta } from "/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue?macro=true";
import { default as indexZ753QJASLnMeta } from "/usr/src/app/pages/national-targets/my-country/part-1/index.vue?macro=true";
import { default as newoyNds3oRk3Meta } from "/usr/src/app/pages/national-targets/my-country/part-1/new.vue?macro=true";
import { default as edit2QYpjz5YoQMeta } from "/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue?macro=true";
import { default as indexwnpy75vGw7Meta } from "/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue?macro=true";
import { default as viewqURcoCkj38Meta } from "/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue?macro=true";
import { default as indexOJUnEL0XnXMeta } from "/usr/src/app/pages/national-targets/my-country/part-2/index.vue?macro=true";
import { default as newMS6sxO9R2PMeta } from "/usr/src/app/pages/national-targets/my-country/part-2/new.vue?macro=true";
import { default as indexyyK4E4AyLJMeta } from "/usr/src/app/pages/nbsaps/index.vue?macro=true";
import { default as edit8Y1LxXRh7vMeta } from "/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue?macro=true";
import { default as indexlp6EFoeuohMeta } from "/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue?macro=true";
import { default as viewNKttmkdBYAMeta } from "/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue?macro=true";
import { default as indexpMPnSS42HFMeta } from "/usr/src/app/pages/nbsaps/my-country/index.vue?macro=true";
import { default as newECpcWBzEP0Meta } from "/usr/src/app/pages/nbsaps/my-country/new.vue?macro=true";
import { default as _91identifier_93DLiRS5ctyCMeta } from "/usr/src/app/pages/search/[identifier].vue?macro=true";
import { default as indexUzt6YGbMnbMeta } from "/usr/src/app/pages/search/index.vue?macro=true";
import { default as _91identifier_93reiPSm5fFaMeta } from "/usr/src/app/pages/taxonomy/gbf/[identifier].vue?macro=true";
import { default as _91identifier_93jWZnRp8Hh0Meta } from "/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue?macro=true";
import { default as indexrSB8j5cgCBMeta } from "/usr/src/app/pages/taxonomy/gbf/index.vue?macro=true";
import { default as email_45alertsANwd1IQgThMeta } from "/usr/src/app/pages/users/email-alerts.vue?macro=true";
import { default as settingsI2eB0HcpFMeta } from "/usr/src/app/pages/users/setting.vue?macro=true";
import { default as _91_91identifier_93_93g2w7yGOZ1pMeta } from "/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue?macro=true";
import { default as requestseemQCdsGWcMeta } from "/usr/src/app/pages/workflows/requests.vue?macro=true";
export default [
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard___ar",
    path: dashboardhKii8fXWAxMeta?.path ?? "/ar/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard___en___default",
    path: dashboardhKii8fXWAxMeta?.path ?? "/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard___en",
    path: dashboardhKii8fXWAxMeta?.path ?? "/en/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard___fr",
    path: dashboardhKii8fXWAxMeta?.path ?? "/fr/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard___es",
    path: dashboardhKii8fXWAxMeta?.path ?? "/es/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard___ru",
    path: dashboardhKii8fXWAxMeta?.path ?? "/ru/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard___zh",
    path: dashboardhKii8fXWAxMeta?.path ?? "/zh/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_937XfjtpS143Meta?.name ?? "database-identifier___ar",
    path: _91identifier_937XfjtpS143Meta?.path ?? "/ar/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    alias: _91identifier_937XfjtpS143Meta?.alias || [],
    redirect: _91identifier_937XfjtpS143Meta?.redirect,
    component: () => import("/usr/src/app/pages/database/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_937XfjtpS143Meta?.name ?? "database-identifier___en___default",
    path: _91identifier_937XfjtpS143Meta?.path ?? "/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    alias: _91identifier_937XfjtpS143Meta?.alias || [],
    redirect: _91identifier_937XfjtpS143Meta?.redirect,
    component: () => import("/usr/src/app/pages/database/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_937XfjtpS143Meta?.name ?? "database-identifier___en",
    path: _91identifier_937XfjtpS143Meta?.path ?? "/en/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    alias: _91identifier_937XfjtpS143Meta?.alias || [],
    redirect: _91identifier_937XfjtpS143Meta?.redirect,
    component: () => import("/usr/src/app/pages/database/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_937XfjtpS143Meta?.name ?? "database-identifier___fr",
    path: _91identifier_937XfjtpS143Meta?.path ?? "/fr/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    alias: _91identifier_937XfjtpS143Meta?.alias || [],
    redirect: _91identifier_937XfjtpS143Meta?.redirect,
    component: () => import("/usr/src/app/pages/database/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_937XfjtpS143Meta?.name ?? "database-identifier___es",
    path: _91identifier_937XfjtpS143Meta?.path ?? "/es/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    alias: _91identifier_937XfjtpS143Meta?.alias || [],
    redirect: _91identifier_937XfjtpS143Meta?.redirect,
    component: () => import("/usr/src/app/pages/database/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_937XfjtpS143Meta?.name ?? "database-identifier___ru",
    path: _91identifier_937XfjtpS143Meta?.path ?? "/ru/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    alias: _91identifier_937XfjtpS143Meta?.alias || [],
    redirect: _91identifier_937XfjtpS143Meta?.redirect,
    component: () => import("/usr/src/app/pages/database/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_937XfjtpS143Meta?.name ?? "database-identifier___zh",
    path: _91identifier_937XfjtpS143Meta?.path ?? "/zh/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    alias: _91identifier_937XfjtpS143Meta?.alias || [],
    redirect: _91identifier_937XfjtpS143Meta?.redirect,
    component: () => import("/usr/src/app/pages/database/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _403nSVv3KgT0OMeta?.name ?? "error-403___ar",
    path: _403nSVv3KgT0OMeta?.path ?? "/ar/error/403",
    meta: _403nSVv3KgT0OMeta || {},
    alias: _403nSVv3KgT0OMeta?.alias || [],
    redirect: _403nSVv3KgT0OMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/403.vue").then(m => m.default || m)
  },
  {
    name: _403nSVv3KgT0OMeta?.name ?? "error-403___en___default",
    path: _403nSVv3KgT0OMeta?.path ?? "/error/403",
    meta: _403nSVv3KgT0OMeta || {},
    alias: _403nSVv3KgT0OMeta?.alias || [],
    redirect: _403nSVv3KgT0OMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/403.vue").then(m => m.default || m)
  },
  {
    name: _403nSVv3KgT0OMeta?.name ?? "error-403___en",
    path: _403nSVv3KgT0OMeta?.path ?? "/en/error/403",
    meta: _403nSVv3KgT0OMeta || {},
    alias: _403nSVv3KgT0OMeta?.alias || [],
    redirect: _403nSVv3KgT0OMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/403.vue").then(m => m.default || m)
  },
  {
    name: _403nSVv3KgT0OMeta?.name ?? "error-403___fr",
    path: _403nSVv3KgT0OMeta?.path ?? "/fr/error/403",
    meta: _403nSVv3KgT0OMeta || {},
    alias: _403nSVv3KgT0OMeta?.alias || [],
    redirect: _403nSVv3KgT0OMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/403.vue").then(m => m.default || m)
  },
  {
    name: _403nSVv3KgT0OMeta?.name ?? "error-403___es",
    path: _403nSVv3KgT0OMeta?.path ?? "/es/error/403",
    meta: _403nSVv3KgT0OMeta || {},
    alias: _403nSVv3KgT0OMeta?.alias || [],
    redirect: _403nSVv3KgT0OMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/403.vue").then(m => m.default || m)
  },
  {
    name: _403nSVv3KgT0OMeta?.name ?? "error-403___ru",
    path: _403nSVv3KgT0OMeta?.path ?? "/ru/error/403",
    meta: _403nSVv3KgT0OMeta || {},
    alias: _403nSVv3KgT0OMeta?.alias || [],
    redirect: _403nSVv3KgT0OMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/403.vue").then(m => m.default || m)
  },
  {
    name: _403nSVv3KgT0OMeta?.name ?? "error-403___zh",
    path: _403nSVv3KgT0OMeta?.path ?? "/zh/error/403",
    meta: _403nSVv3KgT0OMeta || {},
    alias: _403nSVv3KgT0OMeta?.alias || [],
    redirect: _403nSVv3KgT0OMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/403.vue").then(m => m.default || m)
  },
  {
    name: _404WivUUbKe5wMeta?.name ?? "error-404___ar",
    path: _404WivUUbKe5wMeta?.path ?? "/ar/error/404",
    meta: _404WivUUbKe5wMeta || {},
    alias: _404WivUUbKe5wMeta?.alias || [],
    redirect: _404WivUUbKe5wMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404WivUUbKe5wMeta?.name ?? "error-404___en___default",
    path: _404WivUUbKe5wMeta?.path ?? "/error/404",
    meta: _404WivUUbKe5wMeta || {},
    alias: _404WivUUbKe5wMeta?.alias || [],
    redirect: _404WivUUbKe5wMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404WivUUbKe5wMeta?.name ?? "error-404___en",
    path: _404WivUUbKe5wMeta?.path ?? "/en/error/404",
    meta: _404WivUUbKe5wMeta || {},
    alias: _404WivUUbKe5wMeta?.alias || [],
    redirect: _404WivUUbKe5wMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404WivUUbKe5wMeta?.name ?? "error-404___fr",
    path: _404WivUUbKe5wMeta?.path ?? "/fr/error/404",
    meta: _404WivUUbKe5wMeta || {},
    alias: _404WivUUbKe5wMeta?.alias || [],
    redirect: _404WivUUbKe5wMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404WivUUbKe5wMeta?.name ?? "error-404___es",
    path: _404WivUUbKe5wMeta?.path ?? "/es/error/404",
    meta: _404WivUUbKe5wMeta || {},
    alias: _404WivUUbKe5wMeta?.alias || [],
    redirect: _404WivUUbKe5wMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404WivUUbKe5wMeta?.name ?? "error-404___ru",
    path: _404WivUUbKe5wMeta?.path ?? "/ru/error/404",
    meta: _404WivUUbKe5wMeta || {},
    alias: _404WivUUbKe5wMeta?.alias || [],
    redirect: _404WivUUbKe5wMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _404WivUUbKe5wMeta?.name ?? "error-404___zh",
    path: _404WivUUbKe5wMeta?.path ?? "/zh/error/404",
    meta: _404WivUUbKe5wMeta || {},
    alias: _404WivUUbKe5wMeta?.alias || [],
    redirect: _404WivUUbKe5wMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: _500Wi55kHlytRMeta?.name ?? "error-500___ar",
    path: _500Wi55kHlytRMeta?.path ?? "/ar/error/500",
    meta: _500Wi55kHlytRMeta || {},
    alias: _500Wi55kHlytRMeta?.alias || [],
    redirect: _500Wi55kHlytRMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500Wi55kHlytRMeta?.name ?? "error-500___en___default",
    path: _500Wi55kHlytRMeta?.path ?? "/error/500",
    meta: _500Wi55kHlytRMeta || {},
    alias: _500Wi55kHlytRMeta?.alias || [],
    redirect: _500Wi55kHlytRMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500Wi55kHlytRMeta?.name ?? "error-500___en",
    path: _500Wi55kHlytRMeta?.path ?? "/en/error/500",
    meta: _500Wi55kHlytRMeta || {},
    alias: _500Wi55kHlytRMeta?.alias || [],
    redirect: _500Wi55kHlytRMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500Wi55kHlytRMeta?.name ?? "error-500___fr",
    path: _500Wi55kHlytRMeta?.path ?? "/fr/error/500",
    meta: _500Wi55kHlytRMeta || {},
    alias: _500Wi55kHlytRMeta?.alias || [],
    redirect: _500Wi55kHlytRMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500Wi55kHlytRMeta?.name ?? "error-500___es",
    path: _500Wi55kHlytRMeta?.path ?? "/es/error/500",
    meta: _500Wi55kHlytRMeta || {},
    alias: _500Wi55kHlytRMeta?.alias || [],
    redirect: _500Wi55kHlytRMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500Wi55kHlytRMeta?.name ?? "error-500___ru",
    path: _500Wi55kHlytRMeta?.path ?? "/ru/error/500",
    meta: _500Wi55kHlytRMeta || {},
    alias: _500Wi55kHlytRMeta?.alias || [],
    redirect: _500Wi55kHlytRMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: _500Wi55kHlytRMeta?.name ?? "error-500___zh",
    path: _500Wi55kHlytRMeta?.path ?? "/zh/error/500",
    meta: _500Wi55kHlytRMeta || {},
    alias: _500Wi55kHlytRMeta?.alias || [],
    redirect: _500Wi55kHlytRMeta?.redirect,
    component: () => import("/usr/src/app/pages/error/500.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___ar",
    path: indexgw2ikJsyhcMeta?.path ?? "/ar",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___en___default",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___en",
    path: indexgw2ikJsyhcMeta?.path ?? "/en",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___fr",
    path: indexgw2ikJsyhcMeta?.path ?? "/fr",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___es",
    path: indexgw2ikJsyhcMeta?.path ?? "/es",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___ru",
    path: indexgw2ikJsyhcMeta?.path ?? "/ru",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index___zh",
    path: indexgw2ikJsyhcMeta?.path ?? "/zh",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93QuSrBUP6SFMeta?.name ?? "knowledge-base-articles-identifier___ar",
    path: _91identifier_93QuSrBUP6SFMeta?.path ?? "/ar/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    alias: _91identifier_93QuSrBUP6SFMeta?.alias || [],
    redirect: _91identifier_93QuSrBUP6SFMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93QuSrBUP6SFMeta?.name ?? "knowledge-base-articles-identifier___en___default",
    path: _91identifier_93QuSrBUP6SFMeta?.path ?? "/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    alias: _91identifier_93QuSrBUP6SFMeta?.alias || [],
    redirect: _91identifier_93QuSrBUP6SFMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93QuSrBUP6SFMeta?.name ?? "knowledge-base-articles-identifier___en",
    path: _91identifier_93QuSrBUP6SFMeta?.path ?? "/en/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    alias: _91identifier_93QuSrBUP6SFMeta?.alias || [],
    redirect: _91identifier_93QuSrBUP6SFMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93QuSrBUP6SFMeta?.name ?? "knowledge-base-articles-identifier___fr",
    path: _91identifier_93QuSrBUP6SFMeta?.path ?? "/fr/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    alias: _91identifier_93QuSrBUP6SFMeta?.alias || [],
    redirect: _91identifier_93QuSrBUP6SFMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93QuSrBUP6SFMeta?.name ?? "knowledge-base-articles-identifier___es",
    path: _91identifier_93QuSrBUP6SFMeta?.path ?? "/es/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    alias: _91identifier_93QuSrBUP6SFMeta?.alias || [],
    redirect: _91identifier_93QuSrBUP6SFMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93QuSrBUP6SFMeta?.name ?? "knowledge-base-articles-identifier___ru",
    path: _91identifier_93QuSrBUP6SFMeta?.path ?? "/ru/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    alias: _91identifier_93QuSrBUP6SFMeta?.alias || [],
    redirect: _91identifier_93QuSrBUP6SFMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93QuSrBUP6SFMeta?.name ?? "knowledge-base-articles-identifier___zh",
    path: _91identifier_93QuSrBUP6SFMeta?.path ?? "/zh/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    alias: _91identifier_93QuSrBUP6SFMeta?.alias || [],
    redirect: _91identifier_93QuSrBUP6SFMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue").then(m => m.default || m)
  },
  {
    name: indexPK4J6TyKtnMeta?.name ?? "knowledge-base-articles___ar",
    path: indexPK4J6TyKtnMeta?.path ?? "/ar/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    alias: indexPK4J6TyKtnMeta?.alias || [],
    redirect: indexPK4J6TyKtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPK4J6TyKtnMeta?.name ?? "knowledge-base-articles___en___default",
    path: indexPK4J6TyKtnMeta?.path ?? "/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    alias: indexPK4J6TyKtnMeta?.alias || [],
    redirect: indexPK4J6TyKtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPK4J6TyKtnMeta?.name ?? "knowledge-base-articles___en",
    path: indexPK4J6TyKtnMeta?.path ?? "/en/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    alias: indexPK4J6TyKtnMeta?.alias || [],
    redirect: indexPK4J6TyKtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPK4J6TyKtnMeta?.name ?? "knowledge-base-articles___fr",
    path: indexPK4J6TyKtnMeta?.path ?? "/fr/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    alias: indexPK4J6TyKtnMeta?.alias || [],
    redirect: indexPK4J6TyKtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPK4J6TyKtnMeta?.name ?? "knowledge-base-articles___es",
    path: indexPK4J6TyKtnMeta?.path ?? "/es/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    alias: indexPK4J6TyKtnMeta?.alias || [],
    redirect: indexPK4J6TyKtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPK4J6TyKtnMeta?.name ?? "knowledge-base-articles___ru",
    path: indexPK4J6TyKtnMeta?.path ?? "/ru/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    alias: indexPK4J6TyKtnMeta?.alias || [],
    redirect: indexPK4J6TyKtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexPK4J6TyKtnMeta?.name ?? "knowledge-base-articles___zh",
    path: indexPK4J6TyKtnMeta?.path ?? "/zh/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    alias: indexPK4J6TyKtnMeta?.alias || [],
    redirect: indexPK4J6TyKtnMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue").then(m => m.default || m)
  },
  {
    name: index9UaQHjx8Q7Meta?.name ?? "knowledge-base___ar",
    path: index9UaQHjx8Q7Meta?.path ?? "/ar/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    alias: index9UaQHjx8Q7Meta?.alias || [],
    redirect: index9UaQHjx8Q7Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: index9UaQHjx8Q7Meta?.name ?? "knowledge-base___en___default",
    path: index9UaQHjx8Q7Meta?.path ?? "/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    alias: index9UaQHjx8Q7Meta?.alias || [],
    redirect: index9UaQHjx8Q7Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: index9UaQHjx8Q7Meta?.name ?? "knowledge-base___en",
    path: index9UaQHjx8Q7Meta?.path ?? "/en/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    alias: index9UaQHjx8Q7Meta?.alias || [],
    redirect: index9UaQHjx8Q7Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: index9UaQHjx8Q7Meta?.name ?? "knowledge-base___fr",
    path: index9UaQHjx8Q7Meta?.path ?? "/fr/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    alias: index9UaQHjx8Q7Meta?.alias || [],
    redirect: index9UaQHjx8Q7Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: index9UaQHjx8Q7Meta?.name ?? "knowledge-base___es",
    path: index9UaQHjx8Q7Meta?.path ?? "/es/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    alias: index9UaQHjx8Q7Meta?.alias || [],
    redirect: index9UaQHjx8Q7Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: index9UaQHjx8Q7Meta?.name ?? "knowledge-base___ru",
    path: index9UaQHjx8Q7Meta?.path ?? "/ru/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    alias: index9UaQHjx8Q7Meta?.alias || [],
    redirect: index9UaQHjx8Q7Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: index9UaQHjx8Q7Meta?.name ?? "knowledge-base___zh",
    path: index9UaQHjx8Q7Meta?.path ?? "/zh/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    alias: index9UaQHjx8Q7Meta?.alias || [],
    redirect: index9UaQHjx8Q7Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTysiFnUI5Meta?.name ?? "knowledge-base-tags-tag___ar",
    path: indexLTysiFnUI5Meta?.path ?? "/ar/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    alias: indexLTysiFnUI5Meta?.alias || [],
    redirect: indexLTysiFnUI5Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTysiFnUI5Meta?.name ?? "knowledge-base-tags-tag___en___default",
    path: indexLTysiFnUI5Meta?.path ?? "/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    alias: indexLTysiFnUI5Meta?.alias || [],
    redirect: indexLTysiFnUI5Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTysiFnUI5Meta?.name ?? "knowledge-base-tags-tag___en",
    path: indexLTysiFnUI5Meta?.path ?? "/en/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    alias: indexLTysiFnUI5Meta?.alias || [],
    redirect: indexLTysiFnUI5Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTysiFnUI5Meta?.name ?? "knowledge-base-tags-tag___fr",
    path: indexLTysiFnUI5Meta?.path ?? "/fr/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    alias: indexLTysiFnUI5Meta?.alias || [],
    redirect: indexLTysiFnUI5Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTysiFnUI5Meta?.name ?? "knowledge-base-tags-tag___es",
    path: indexLTysiFnUI5Meta?.path ?? "/es/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    alias: indexLTysiFnUI5Meta?.alias || [],
    redirect: indexLTysiFnUI5Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTysiFnUI5Meta?.name ?? "knowledge-base-tags-tag___ru",
    path: indexLTysiFnUI5Meta?.path ?? "/ru/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    alias: indexLTysiFnUI5Meta?.alias || [],
    redirect: indexLTysiFnUI5Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLTysiFnUI5Meta?.name ?? "knowledge-base-tags-tag___zh",
    path: indexLTysiFnUI5Meta?.path ?? "/zh/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    alias: indexLTysiFnUI5Meta?.alias || [],
    redirect: indexLTysiFnUI5Meta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZZhFjk9ZfGMeta?.name ?? "knowledge-base-tags___ar",
    path: indexZZhFjk9ZfGMeta?.path ?? "/ar/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    alias: indexZZhFjk9ZfGMeta?.alias || [],
    redirect: indexZZhFjk9ZfGMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexZZhFjk9ZfGMeta?.name ?? "knowledge-base-tags___en___default",
    path: indexZZhFjk9ZfGMeta?.path ?? "/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    alias: indexZZhFjk9ZfGMeta?.alias || [],
    redirect: indexZZhFjk9ZfGMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexZZhFjk9ZfGMeta?.name ?? "knowledge-base-tags___en",
    path: indexZZhFjk9ZfGMeta?.path ?? "/en/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    alias: indexZZhFjk9ZfGMeta?.alias || [],
    redirect: indexZZhFjk9ZfGMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexZZhFjk9ZfGMeta?.name ?? "knowledge-base-tags___fr",
    path: indexZZhFjk9ZfGMeta?.path ?? "/fr/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    alias: indexZZhFjk9ZfGMeta?.alias || [],
    redirect: indexZZhFjk9ZfGMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexZZhFjk9ZfGMeta?.name ?? "knowledge-base-tags___es",
    path: indexZZhFjk9ZfGMeta?.path ?? "/es/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    alias: indexZZhFjk9ZfGMeta?.alias || [],
    redirect: indexZZhFjk9ZfGMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexZZhFjk9ZfGMeta?.name ?? "knowledge-base-tags___ru",
    path: indexZZhFjk9ZfGMeta?.path ?? "/ru/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    alias: indexZZhFjk9ZfGMeta?.alias || [],
    redirect: indexZZhFjk9ZfGMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexZZhFjk9ZfGMeta?.name ?? "knowledge-base-tags___zh",
    path: indexZZhFjk9ZfGMeta?.path ?? "/zh/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    alias: indexZZhFjk9ZfGMeta?.alias || [],
    redirect: indexZZhFjk9ZfGMeta?.redirect,
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexl38GLAvKVHMeta?.name ?? "national-reports___ar",
    path: indexl38GLAvKVHMeta?.path ?? "/ar/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    alias: indexl38GLAvKVHMeta?.alias || [],
    redirect: indexl38GLAvKVHMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexl38GLAvKVHMeta?.name ?? "national-reports___en___default",
    path: indexl38GLAvKVHMeta?.path ?? "/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    alias: indexl38GLAvKVHMeta?.alias || [],
    redirect: indexl38GLAvKVHMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexl38GLAvKVHMeta?.name ?? "national-reports___en",
    path: indexl38GLAvKVHMeta?.path ?? "/en/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    alias: indexl38GLAvKVHMeta?.alias || [],
    redirect: indexl38GLAvKVHMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexl38GLAvKVHMeta?.name ?? "national-reports___fr",
    path: indexl38GLAvKVHMeta?.path ?? "/fr/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    alias: indexl38GLAvKVHMeta?.alias || [],
    redirect: indexl38GLAvKVHMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexl38GLAvKVHMeta?.name ?? "national-reports___es",
    path: indexl38GLAvKVHMeta?.path ?? "/es/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    alias: indexl38GLAvKVHMeta?.alias || [],
    redirect: indexl38GLAvKVHMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexl38GLAvKVHMeta?.name ?? "national-reports___ru",
    path: indexl38GLAvKVHMeta?.path ?? "/ru/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    alias: indexl38GLAvKVHMeta?.alias || [],
    redirect: indexl38GLAvKVHMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexl38GLAvKVHMeta?.name ?? "national-reports___zh",
    path: indexl38GLAvKVHMeta?.path ?? "/zh/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    alias: indexl38GLAvKVHMeta?.alias || [],
    redirect: indexl38GLAvKVHMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexsV0YGwxtHhMeta?.name ?? "national-reports-nr7___ar",
    path: indexsV0YGwxtHhMeta?.path ?? "/ar/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    alias: indexsV0YGwxtHhMeta?.alias || [],
    redirect: indexsV0YGwxtHhMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue").then(m => m.default || m)
  },
  {
    name: indexsV0YGwxtHhMeta?.name ?? "national-reports-nr7___en___default",
    path: indexsV0YGwxtHhMeta?.path ?? "/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    alias: indexsV0YGwxtHhMeta?.alias || [],
    redirect: indexsV0YGwxtHhMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue").then(m => m.default || m)
  },
  {
    name: indexsV0YGwxtHhMeta?.name ?? "national-reports-nr7___en",
    path: indexsV0YGwxtHhMeta?.path ?? "/en/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    alias: indexsV0YGwxtHhMeta?.alias || [],
    redirect: indexsV0YGwxtHhMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue").then(m => m.default || m)
  },
  {
    name: indexsV0YGwxtHhMeta?.name ?? "national-reports-nr7___fr",
    path: indexsV0YGwxtHhMeta?.path ?? "/fr/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    alias: indexsV0YGwxtHhMeta?.alias || [],
    redirect: indexsV0YGwxtHhMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue").then(m => m.default || m)
  },
  {
    name: indexsV0YGwxtHhMeta?.name ?? "national-reports-nr7___es",
    path: indexsV0YGwxtHhMeta?.path ?? "/es/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    alias: indexsV0YGwxtHhMeta?.alias || [],
    redirect: indexsV0YGwxtHhMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue").then(m => m.default || m)
  },
  {
    name: indexsV0YGwxtHhMeta?.name ?? "national-reports-nr7___ru",
    path: indexsV0YGwxtHhMeta?.path ?? "/ru/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    alias: indexsV0YGwxtHhMeta?.alias || [],
    redirect: indexsV0YGwxtHhMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue").then(m => m.default || m)
  },
  {
    name: indexsV0YGwxtHhMeta?.name ?? "national-reports-nr7___zh",
    path: indexsV0YGwxtHhMeta?.path ?? "/zh/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    alias: indexsV0YGwxtHhMeta?.alias || [],
    redirect: indexsV0YGwxtHhMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAaag6k3KSMeta?.name ?? "national-reports-nr7-my-country-edit___ar",
    path: indexqAaag6k3KSMeta?.path ?? "/ar/national-reports/nr7/my-country/edit",
    meta: indexqAaag6k3KSMeta || {},
    alias: indexqAaag6k3KSMeta?.alias || [],
    redirect: indexqAaag6k3KSMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAaag6k3KSMeta?.name ?? "national-reports-nr7-my-country-edit___en___default",
    path: indexqAaag6k3KSMeta?.path ?? "/national-reports/nr7/my-country/edit",
    meta: indexqAaag6k3KSMeta || {},
    alias: indexqAaag6k3KSMeta?.alias || [],
    redirect: indexqAaag6k3KSMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAaag6k3KSMeta?.name ?? "national-reports-nr7-my-country-edit___en",
    path: indexqAaag6k3KSMeta?.path ?? "/en/national-reports/nr7/my-country/edit",
    meta: indexqAaag6k3KSMeta || {},
    alias: indexqAaag6k3KSMeta?.alias || [],
    redirect: indexqAaag6k3KSMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAaag6k3KSMeta?.name ?? "national-reports-nr7-my-country-edit___fr",
    path: indexqAaag6k3KSMeta?.path ?? "/fr/national-reports/nr7/my-country/edit",
    meta: indexqAaag6k3KSMeta || {},
    alias: indexqAaag6k3KSMeta?.alias || [],
    redirect: indexqAaag6k3KSMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAaag6k3KSMeta?.name ?? "national-reports-nr7-my-country-edit___es",
    path: indexqAaag6k3KSMeta?.path ?? "/es/national-reports/nr7/my-country/edit",
    meta: indexqAaag6k3KSMeta || {},
    alias: indexqAaag6k3KSMeta?.alias || [],
    redirect: indexqAaag6k3KSMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAaag6k3KSMeta?.name ?? "national-reports-nr7-my-country-edit___ru",
    path: indexqAaag6k3KSMeta?.path ?? "/ru/national-reports/nr7/my-country/edit",
    meta: indexqAaag6k3KSMeta || {},
    alias: indexqAaag6k3KSMeta?.alias || [],
    redirect: indexqAaag6k3KSMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexqAaag6k3KSMeta?.name ?? "national-reports-nr7-my-country-edit___zh",
    path: indexqAaag6k3KSMeta?.path ?? "/zh/national-reports/nr7/my-country/edit",
    meta: indexqAaag6k3KSMeta || {},
    alias: indexqAaag6k3KSMeta?.alias || [],
    redirect: indexqAaag6k3KSMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indicator_45datar4FCObtcGyMeta?.name ?? "national-reports-nr7-my-country-edit-indicator-data___ar",
    path: indicator_45datar4FCObtcGyMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    alias: indicator_45datar4FCObtcGyMeta?.alias || [],
    redirect: indicator_45datar4FCObtcGyMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue").then(m => m.default || m)
  },
  {
    name: indicator_45datar4FCObtcGyMeta?.name ?? "national-reports-nr7-my-country-edit-indicator-data___en___default",
    path: indicator_45datar4FCObtcGyMeta?.path ?? "/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    alias: indicator_45datar4FCObtcGyMeta?.alias || [],
    redirect: indicator_45datar4FCObtcGyMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue").then(m => m.default || m)
  },
  {
    name: indicator_45datar4FCObtcGyMeta?.name ?? "national-reports-nr7-my-country-edit-indicator-data___en",
    path: indicator_45datar4FCObtcGyMeta?.path ?? "/en/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    alias: indicator_45datar4FCObtcGyMeta?.alias || [],
    redirect: indicator_45datar4FCObtcGyMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue").then(m => m.default || m)
  },
  {
    name: indicator_45datar4FCObtcGyMeta?.name ?? "national-reports-nr7-my-country-edit-indicator-data___fr",
    path: indicator_45datar4FCObtcGyMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    alias: indicator_45datar4FCObtcGyMeta?.alias || [],
    redirect: indicator_45datar4FCObtcGyMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue").then(m => m.default || m)
  },
  {
    name: indicator_45datar4FCObtcGyMeta?.name ?? "national-reports-nr7-my-country-edit-indicator-data___es",
    path: indicator_45datar4FCObtcGyMeta?.path ?? "/es/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    alias: indicator_45datar4FCObtcGyMeta?.alias || [],
    redirect: indicator_45datar4FCObtcGyMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue").then(m => m.default || m)
  },
  {
    name: indicator_45datar4FCObtcGyMeta?.name ?? "national-reports-nr7-my-country-edit-indicator-data___ru",
    path: indicator_45datar4FCObtcGyMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    alias: indicator_45datar4FCObtcGyMeta?.alias || [],
    redirect: indicator_45datar4FCObtcGyMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue").then(m => m.default || m)
  },
  {
    name: indicator_45datar4FCObtcGyMeta?.name ?? "national-reports-nr7-my-country-edit-indicator-data___zh",
    path: indicator_45datar4FCObtcGyMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    alias: indicator_45datar4FCObtcGyMeta?.alias || [],
    redirect: indicator_45datar4FCObtcGyMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue").then(m => m.default || m)
  },
  {
    name: overviewf9kPW4zRjgMeta?.name ?? "national-reports-nr7-my-country-edit-overview___ar",
    path: overviewf9kPW4zRjgMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    alias: overviewf9kPW4zRjgMeta?.alias || [],
    redirect: overviewf9kPW4zRjgMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewf9kPW4zRjgMeta?.name ?? "national-reports-nr7-my-country-edit-overview___en___default",
    path: overviewf9kPW4zRjgMeta?.path ?? "/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    alias: overviewf9kPW4zRjgMeta?.alias || [],
    redirect: overviewf9kPW4zRjgMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewf9kPW4zRjgMeta?.name ?? "national-reports-nr7-my-country-edit-overview___en",
    path: overviewf9kPW4zRjgMeta?.path ?? "/en/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    alias: overviewf9kPW4zRjgMeta?.alias || [],
    redirect: overviewf9kPW4zRjgMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewf9kPW4zRjgMeta?.name ?? "national-reports-nr7-my-country-edit-overview___fr",
    path: overviewf9kPW4zRjgMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    alias: overviewf9kPW4zRjgMeta?.alias || [],
    redirect: overviewf9kPW4zRjgMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewf9kPW4zRjgMeta?.name ?? "national-reports-nr7-my-country-edit-overview___es",
    path: overviewf9kPW4zRjgMeta?.path ?? "/es/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    alias: overviewf9kPW4zRjgMeta?.alias || [],
    redirect: overviewf9kPW4zRjgMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewf9kPW4zRjgMeta?.name ?? "national-reports-nr7-my-country-edit-overview___ru",
    path: overviewf9kPW4zRjgMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    alias: overviewf9kPW4zRjgMeta?.alias || [],
    redirect: overviewf9kPW4zRjgMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewf9kPW4zRjgMeta?.name ?? "national-reports-nr7-my-country-edit-overview___zh",
    path: overviewf9kPW4zRjgMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    alias: overviewf9kPW4zRjgMeta?.alias || [],
    redirect: overviewf9kPW4zRjgMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue").then(m => m.default || m)
  },
  {
    name: section_45annexJuwaxMeKOoMeta?.name ?? "national-reports-nr7-my-country-edit-section-annex___ar",
    path: section_45annexJuwaxMeKOoMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    alias: section_45annexJuwaxMeKOoMeta?.alias || [],
    redirect: section_45annexJuwaxMeKOoMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue").then(m => m.default || m)
  },
  {
    name: section_45annexJuwaxMeKOoMeta?.name ?? "national-reports-nr7-my-country-edit-section-annex___en___default",
    path: section_45annexJuwaxMeKOoMeta?.path ?? "/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    alias: section_45annexJuwaxMeKOoMeta?.alias || [],
    redirect: section_45annexJuwaxMeKOoMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue").then(m => m.default || m)
  },
  {
    name: section_45annexJuwaxMeKOoMeta?.name ?? "national-reports-nr7-my-country-edit-section-annex___en",
    path: section_45annexJuwaxMeKOoMeta?.path ?? "/en/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    alias: section_45annexJuwaxMeKOoMeta?.alias || [],
    redirect: section_45annexJuwaxMeKOoMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue").then(m => m.default || m)
  },
  {
    name: section_45annexJuwaxMeKOoMeta?.name ?? "national-reports-nr7-my-country-edit-section-annex___fr",
    path: section_45annexJuwaxMeKOoMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    alias: section_45annexJuwaxMeKOoMeta?.alias || [],
    redirect: section_45annexJuwaxMeKOoMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue").then(m => m.default || m)
  },
  {
    name: section_45annexJuwaxMeKOoMeta?.name ?? "national-reports-nr7-my-country-edit-section-annex___es",
    path: section_45annexJuwaxMeKOoMeta?.path ?? "/es/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    alias: section_45annexJuwaxMeKOoMeta?.alias || [],
    redirect: section_45annexJuwaxMeKOoMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue").then(m => m.default || m)
  },
  {
    name: section_45annexJuwaxMeKOoMeta?.name ?? "national-reports-nr7-my-country-edit-section-annex___ru",
    path: section_45annexJuwaxMeKOoMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    alias: section_45annexJuwaxMeKOoMeta?.alias || [],
    redirect: section_45annexJuwaxMeKOoMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue").then(m => m.default || m)
  },
  {
    name: section_45annexJuwaxMeKOoMeta?.name ?? "national-reports-nr7-my-country-edit-section-annex___zh",
    path: section_45annexJuwaxMeKOoMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    alias: section_45annexJuwaxMeKOoMeta?.alias || [],
    redirect: section_45annexJuwaxMeKOoMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue").then(m => m.default || m)
  },
  {
    name: section_45IOUxLtY92lYMeta?.name ?? "national-reports-nr7-my-country-edit-section-I___ar",
    path: section_45IOUxLtY92lYMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    alias: section_45IOUxLtY92lYMeta?.alias || [],
    redirect: section_45IOUxLtY92lYMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue").then(m => m.default || m)
  },
  {
    name: section_45IOUxLtY92lYMeta?.name ?? "national-reports-nr7-my-country-edit-section-I___en___default",
    path: section_45IOUxLtY92lYMeta?.path ?? "/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    alias: section_45IOUxLtY92lYMeta?.alias || [],
    redirect: section_45IOUxLtY92lYMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue").then(m => m.default || m)
  },
  {
    name: section_45IOUxLtY92lYMeta?.name ?? "national-reports-nr7-my-country-edit-section-I___en",
    path: section_45IOUxLtY92lYMeta?.path ?? "/en/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    alias: section_45IOUxLtY92lYMeta?.alias || [],
    redirect: section_45IOUxLtY92lYMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue").then(m => m.default || m)
  },
  {
    name: section_45IOUxLtY92lYMeta?.name ?? "national-reports-nr7-my-country-edit-section-I___fr",
    path: section_45IOUxLtY92lYMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    alias: section_45IOUxLtY92lYMeta?.alias || [],
    redirect: section_45IOUxLtY92lYMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue").then(m => m.default || m)
  },
  {
    name: section_45IOUxLtY92lYMeta?.name ?? "national-reports-nr7-my-country-edit-section-I___es",
    path: section_45IOUxLtY92lYMeta?.path ?? "/es/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    alias: section_45IOUxLtY92lYMeta?.alias || [],
    redirect: section_45IOUxLtY92lYMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue").then(m => m.default || m)
  },
  {
    name: section_45IOUxLtY92lYMeta?.name ?? "national-reports-nr7-my-country-edit-section-I___ru",
    path: section_45IOUxLtY92lYMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    alias: section_45IOUxLtY92lYMeta?.alias || [],
    redirect: section_45IOUxLtY92lYMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue").then(m => m.default || m)
  },
  {
    name: section_45IOUxLtY92lYMeta?.name ?? "national-reports-nr7-my-country-edit-section-I___zh",
    path: section_45IOUxLtY92lYMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    alias: section_45IOUxLtY92lYMeta?.alias || [],
    redirect: section_45IOUxLtY92lYMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue").then(m => m.default || m)
  },
  {
    name: section_45IIvPfvYsvtFnMeta?.name ?? "national-reports-nr7-my-country-edit-section-II___ar",
    path: section_45IIvPfvYsvtFnMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    alias: section_45IIvPfvYsvtFnMeta?.alias || [],
    redirect: section_45IIvPfvYsvtFnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue").then(m => m.default || m)
  },
  {
    name: section_45IIvPfvYsvtFnMeta?.name ?? "national-reports-nr7-my-country-edit-section-II___en___default",
    path: section_45IIvPfvYsvtFnMeta?.path ?? "/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    alias: section_45IIvPfvYsvtFnMeta?.alias || [],
    redirect: section_45IIvPfvYsvtFnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue").then(m => m.default || m)
  },
  {
    name: section_45IIvPfvYsvtFnMeta?.name ?? "national-reports-nr7-my-country-edit-section-II___en",
    path: section_45IIvPfvYsvtFnMeta?.path ?? "/en/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    alias: section_45IIvPfvYsvtFnMeta?.alias || [],
    redirect: section_45IIvPfvYsvtFnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue").then(m => m.default || m)
  },
  {
    name: section_45IIvPfvYsvtFnMeta?.name ?? "national-reports-nr7-my-country-edit-section-II___fr",
    path: section_45IIvPfvYsvtFnMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    alias: section_45IIvPfvYsvtFnMeta?.alias || [],
    redirect: section_45IIvPfvYsvtFnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue").then(m => m.default || m)
  },
  {
    name: section_45IIvPfvYsvtFnMeta?.name ?? "national-reports-nr7-my-country-edit-section-II___es",
    path: section_45IIvPfvYsvtFnMeta?.path ?? "/es/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    alias: section_45IIvPfvYsvtFnMeta?.alias || [],
    redirect: section_45IIvPfvYsvtFnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue").then(m => m.default || m)
  },
  {
    name: section_45IIvPfvYsvtFnMeta?.name ?? "national-reports-nr7-my-country-edit-section-II___ru",
    path: section_45IIvPfvYsvtFnMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    alias: section_45IIvPfvYsvtFnMeta?.alias || [],
    redirect: section_45IIvPfvYsvtFnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue").then(m => m.default || m)
  },
  {
    name: section_45IIvPfvYsvtFnMeta?.name ?? "national-reports-nr7-my-country-edit-section-II___zh",
    path: section_45IIvPfvYsvtFnMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    alias: section_45IIvPfvYsvtFnMeta?.alias || [],
    redirect: section_45IIvPfvYsvtFnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue").then(m => m.default || m)
  },
  {
    name: section_45IIIKizDNnkcCkMeta?.name ?? "national-reports-nr7-my-country-edit-section-III___ar",
    path: section_45IIIKizDNnkcCkMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    alias: section_45IIIKizDNnkcCkMeta?.alias || [],
    redirect: section_45IIIKizDNnkcCkMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue").then(m => m.default || m)
  },
  {
    name: section_45IIIKizDNnkcCkMeta?.name ?? "national-reports-nr7-my-country-edit-section-III___en___default",
    path: section_45IIIKizDNnkcCkMeta?.path ?? "/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    alias: section_45IIIKizDNnkcCkMeta?.alias || [],
    redirect: section_45IIIKizDNnkcCkMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue").then(m => m.default || m)
  },
  {
    name: section_45IIIKizDNnkcCkMeta?.name ?? "national-reports-nr7-my-country-edit-section-III___en",
    path: section_45IIIKizDNnkcCkMeta?.path ?? "/en/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    alias: section_45IIIKizDNnkcCkMeta?.alias || [],
    redirect: section_45IIIKizDNnkcCkMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue").then(m => m.default || m)
  },
  {
    name: section_45IIIKizDNnkcCkMeta?.name ?? "national-reports-nr7-my-country-edit-section-III___fr",
    path: section_45IIIKizDNnkcCkMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    alias: section_45IIIKizDNnkcCkMeta?.alias || [],
    redirect: section_45IIIKizDNnkcCkMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue").then(m => m.default || m)
  },
  {
    name: section_45IIIKizDNnkcCkMeta?.name ?? "national-reports-nr7-my-country-edit-section-III___es",
    path: section_45IIIKizDNnkcCkMeta?.path ?? "/es/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    alias: section_45IIIKizDNnkcCkMeta?.alias || [],
    redirect: section_45IIIKizDNnkcCkMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue").then(m => m.default || m)
  },
  {
    name: section_45IIIKizDNnkcCkMeta?.name ?? "national-reports-nr7-my-country-edit-section-III___ru",
    path: section_45IIIKizDNnkcCkMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    alias: section_45IIIKizDNnkcCkMeta?.alias || [],
    redirect: section_45IIIKizDNnkcCkMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue").then(m => m.default || m)
  },
  {
    name: section_45IIIKizDNnkcCkMeta?.name ?? "national-reports-nr7-my-country-edit-section-III___zh",
    path: section_45IIIKizDNnkcCkMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    alias: section_45IIIKizDNnkcCkMeta?.alias || [],
    redirect: section_45IIIKizDNnkcCkMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue").then(m => m.default || m)
  },
  {
    name: section_45IVtc4zFIuIXIMeta?.name ?? "national-reports-nr7-my-country-edit-section-IV___ar",
    path: section_45IVtc4zFIuIXIMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    alias: section_45IVtc4zFIuIXIMeta?.alias || [],
    redirect: section_45IVtc4zFIuIXIMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue").then(m => m.default || m)
  },
  {
    name: section_45IVtc4zFIuIXIMeta?.name ?? "national-reports-nr7-my-country-edit-section-IV___en___default",
    path: section_45IVtc4zFIuIXIMeta?.path ?? "/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    alias: section_45IVtc4zFIuIXIMeta?.alias || [],
    redirect: section_45IVtc4zFIuIXIMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue").then(m => m.default || m)
  },
  {
    name: section_45IVtc4zFIuIXIMeta?.name ?? "national-reports-nr7-my-country-edit-section-IV___en",
    path: section_45IVtc4zFIuIXIMeta?.path ?? "/en/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    alias: section_45IVtc4zFIuIXIMeta?.alias || [],
    redirect: section_45IVtc4zFIuIXIMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue").then(m => m.default || m)
  },
  {
    name: section_45IVtc4zFIuIXIMeta?.name ?? "national-reports-nr7-my-country-edit-section-IV___fr",
    path: section_45IVtc4zFIuIXIMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    alias: section_45IVtc4zFIuIXIMeta?.alias || [],
    redirect: section_45IVtc4zFIuIXIMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue").then(m => m.default || m)
  },
  {
    name: section_45IVtc4zFIuIXIMeta?.name ?? "national-reports-nr7-my-country-edit-section-IV___es",
    path: section_45IVtc4zFIuIXIMeta?.path ?? "/es/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    alias: section_45IVtc4zFIuIXIMeta?.alias || [],
    redirect: section_45IVtc4zFIuIXIMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue").then(m => m.default || m)
  },
  {
    name: section_45IVtc4zFIuIXIMeta?.name ?? "national-reports-nr7-my-country-edit-section-IV___ru",
    path: section_45IVtc4zFIuIXIMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    alias: section_45IVtc4zFIuIXIMeta?.alias || [],
    redirect: section_45IVtc4zFIuIXIMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue").then(m => m.default || m)
  },
  {
    name: section_45IVtc4zFIuIXIMeta?.name ?? "national-reports-nr7-my-country-edit-section-IV___zh",
    path: section_45IVtc4zFIuIXIMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    alias: section_45IVtc4zFIuIXIMeta?.alias || [],
    redirect: section_45IVtc4zFIuIXIMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue").then(m => m.default || m)
  },
  {
    name: section_45VCVNGerfoo0Meta?.name ?? "national-reports-nr7-my-country-edit-section-V___ar",
    path: section_45VCVNGerfoo0Meta?.path ?? "/ar/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    alias: section_45VCVNGerfoo0Meta?.alias || [],
    redirect: section_45VCVNGerfoo0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue").then(m => m.default || m)
  },
  {
    name: section_45VCVNGerfoo0Meta?.name ?? "national-reports-nr7-my-country-edit-section-V___en___default",
    path: section_45VCVNGerfoo0Meta?.path ?? "/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    alias: section_45VCVNGerfoo0Meta?.alias || [],
    redirect: section_45VCVNGerfoo0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue").then(m => m.default || m)
  },
  {
    name: section_45VCVNGerfoo0Meta?.name ?? "national-reports-nr7-my-country-edit-section-V___en",
    path: section_45VCVNGerfoo0Meta?.path ?? "/en/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    alias: section_45VCVNGerfoo0Meta?.alias || [],
    redirect: section_45VCVNGerfoo0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue").then(m => m.default || m)
  },
  {
    name: section_45VCVNGerfoo0Meta?.name ?? "national-reports-nr7-my-country-edit-section-V___fr",
    path: section_45VCVNGerfoo0Meta?.path ?? "/fr/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    alias: section_45VCVNGerfoo0Meta?.alias || [],
    redirect: section_45VCVNGerfoo0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue").then(m => m.default || m)
  },
  {
    name: section_45VCVNGerfoo0Meta?.name ?? "national-reports-nr7-my-country-edit-section-V___es",
    path: section_45VCVNGerfoo0Meta?.path ?? "/es/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    alias: section_45VCVNGerfoo0Meta?.alias || [],
    redirect: section_45VCVNGerfoo0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue").then(m => m.default || m)
  },
  {
    name: section_45VCVNGerfoo0Meta?.name ?? "national-reports-nr7-my-country-edit-section-V___ru",
    path: section_45VCVNGerfoo0Meta?.path ?? "/ru/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    alias: section_45VCVNGerfoo0Meta?.alias || [],
    redirect: section_45VCVNGerfoo0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue").then(m => m.default || m)
  },
  {
    name: section_45VCVNGerfoo0Meta?.name ?? "national-reports-nr7-my-country-edit-section-V___zh",
    path: section_45VCVNGerfoo0Meta?.path ?? "/zh/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    alias: section_45VCVNGerfoo0Meta?.alias || [],
    redirect: section_45VCVNGerfoo0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue").then(m => m.default || m)
  },
  {
    name: section_45VIgrD5vjTm7tMeta?.name ?? "national-reports-nr7-my-country-edit-section-VI___ar",
    path: section_45VIgrD5vjTm7tMeta?.path ?? "/ar/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    alias: section_45VIgrD5vjTm7tMeta?.alias || [],
    redirect: section_45VIgrD5vjTm7tMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue").then(m => m.default || m)
  },
  {
    name: section_45VIgrD5vjTm7tMeta?.name ?? "national-reports-nr7-my-country-edit-section-VI___en___default",
    path: section_45VIgrD5vjTm7tMeta?.path ?? "/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    alias: section_45VIgrD5vjTm7tMeta?.alias || [],
    redirect: section_45VIgrD5vjTm7tMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue").then(m => m.default || m)
  },
  {
    name: section_45VIgrD5vjTm7tMeta?.name ?? "national-reports-nr7-my-country-edit-section-VI___en",
    path: section_45VIgrD5vjTm7tMeta?.path ?? "/en/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    alias: section_45VIgrD5vjTm7tMeta?.alias || [],
    redirect: section_45VIgrD5vjTm7tMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue").then(m => m.default || m)
  },
  {
    name: section_45VIgrD5vjTm7tMeta?.name ?? "national-reports-nr7-my-country-edit-section-VI___fr",
    path: section_45VIgrD5vjTm7tMeta?.path ?? "/fr/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    alias: section_45VIgrD5vjTm7tMeta?.alias || [],
    redirect: section_45VIgrD5vjTm7tMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue").then(m => m.default || m)
  },
  {
    name: section_45VIgrD5vjTm7tMeta?.name ?? "national-reports-nr7-my-country-edit-section-VI___es",
    path: section_45VIgrD5vjTm7tMeta?.path ?? "/es/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    alias: section_45VIgrD5vjTm7tMeta?.alias || [],
    redirect: section_45VIgrD5vjTm7tMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue").then(m => m.default || m)
  },
  {
    name: section_45VIgrD5vjTm7tMeta?.name ?? "national-reports-nr7-my-country-edit-section-VI___ru",
    path: section_45VIgrD5vjTm7tMeta?.path ?? "/ru/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    alias: section_45VIgrD5vjTm7tMeta?.alias || [],
    redirect: section_45VIgrD5vjTm7tMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue").then(m => m.default || m)
  },
  {
    name: section_45VIgrD5vjTm7tMeta?.name ?? "national-reports-nr7-my-country-edit-section-VI___zh",
    path: section_45VIgrD5vjTm7tMeta?.path ?? "/zh/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    alias: section_45VIgrD5vjTm7tMeta?.alias || [],
    redirect: section_45VIgrD5vjTm7tMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue").then(m => m.default || m)
  },
  {
    name: indexAMuO34C4sOMeta?.name ?? "national-reports-nr7-my-country___ar",
    path: indexAMuO34C4sOMeta?.path ?? "/ar/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    alias: indexAMuO34C4sOMeta?.alias || [],
    redirect: indexAMuO34C4sOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMuO34C4sOMeta?.name ?? "national-reports-nr7-my-country___en___default",
    path: indexAMuO34C4sOMeta?.path ?? "/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    alias: indexAMuO34C4sOMeta?.alias || [],
    redirect: indexAMuO34C4sOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMuO34C4sOMeta?.name ?? "national-reports-nr7-my-country___en",
    path: indexAMuO34C4sOMeta?.path ?? "/en/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    alias: indexAMuO34C4sOMeta?.alias || [],
    redirect: indexAMuO34C4sOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMuO34C4sOMeta?.name ?? "national-reports-nr7-my-country___fr",
    path: indexAMuO34C4sOMeta?.path ?? "/fr/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    alias: indexAMuO34C4sOMeta?.alias || [],
    redirect: indexAMuO34C4sOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMuO34C4sOMeta?.name ?? "national-reports-nr7-my-country___es",
    path: indexAMuO34C4sOMeta?.path ?? "/es/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    alias: indexAMuO34C4sOMeta?.alias || [],
    redirect: indexAMuO34C4sOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMuO34C4sOMeta?.name ?? "national-reports-nr7-my-country___ru",
    path: indexAMuO34C4sOMeta?.path ?? "/ru/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    alias: indexAMuO34C4sOMeta?.alias || [],
    redirect: indexAMuO34C4sOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexAMuO34C4sOMeta?.name ?? "national-reports-nr7-my-country___zh",
    path: indexAMuO34C4sOMeta?.path ?? "/zh/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    alias: indexAMuO34C4sOMeta?.alias || [],
    redirect: indexAMuO34C4sOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: index6umqEWvPtlMeta?.name ?? "national-reports-nr7-my-country-view___ar",
    path: index6umqEWvPtlMeta?.path ?? "/ar/national-reports/nr7/my-country/view",
    meta: index6umqEWvPtlMeta || {},
    alias: index6umqEWvPtlMeta?.alias || [],
    redirect: index6umqEWvPtlMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue").then(m => m.default || m)
  },
  {
    name: index6umqEWvPtlMeta?.name ?? "national-reports-nr7-my-country-view___en___default",
    path: index6umqEWvPtlMeta?.path ?? "/national-reports/nr7/my-country/view",
    meta: index6umqEWvPtlMeta || {},
    alias: index6umqEWvPtlMeta?.alias || [],
    redirect: index6umqEWvPtlMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue").then(m => m.default || m)
  },
  {
    name: index6umqEWvPtlMeta?.name ?? "national-reports-nr7-my-country-view___en",
    path: index6umqEWvPtlMeta?.path ?? "/en/national-reports/nr7/my-country/view",
    meta: index6umqEWvPtlMeta || {},
    alias: index6umqEWvPtlMeta?.alias || [],
    redirect: index6umqEWvPtlMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue").then(m => m.default || m)
  },
  {
    name: index6umqEWvPtlMeta?.name ?? "national-reports-nr7-my-country-view___fr",
    path: index6umqEWvPtlMeta?.path ?? "/fr/national-reports/nr7/my-country/view",
    meta: index6umqEWvPtlMeta || {},
    alias: index6umqEWvPtlMeta?.alias || [],
    redirect: index6umqEWvPtlMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue").then(m => m.default || m)
  },
  {
    name: index6umqEWvPtlMeta?.name ?? "national-reports-nr7-my-country-view___es",
    path: index6umqEWvPtlMeta?.path ?? "/es/national-reports/nr7/my-country/view",
    meta: index6umqEWvPtlMeta || {},
    alias: index6umqEWvPtlMeta?.alias || [],
    redirect: index6umqEWvPtlMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue").then(m => m.default || m)
  },
  {
    name: index6umqEWvPtlMeta?.name ?? "national-reports-nr7-my-country-view___ru",
    path: index6umqEWvPtlMeta?.path ?? "/ru/national-reports/nr7/my-country/view",
    meta: index6umqEWvPtlMeta || {},
    alias: index6umqEWvPtlMeta?.alias || [],
    redirect: index6umqEWvPtlMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue").then(m => m.default || m)
  },
  {
    name: index6umqEWvPtlMeta?.name ?? "national-reports-nr7-my-country-view___zh",
    path: index6umqEWvPtlMeta?.path ?? "/zh/national-reports/nr7/my-country/view",
    meta: index6umqEWvPtlMeta || {},
    alias: index6umqEWvPtlMeta?.alias || [],
    redirect: index6umqEWvPtlMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue").then(m => m.default || m)
  },
  {
    name: analyzer1ndP9iNI4QMeta?.name ?? "national-targets-analyzer___ar",
    path: analyzer1ndP9iNI4QMeta?.path ?? "/ar/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    alias: analyzer1ndP9iNI4QMeta?.alias || [],
    redirect: analyzer1ndP9iNI4QMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue").then(m => m.default || m)
  },
  {
    name: analyzer1ndP9iNI4QMeta?.name ?? "national-targets-analyzer___en___default",
    path: analyzer1ndP9iNI4QMeta?.path ?? "/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    alias: analyzer1ndP9iNI4QMeta?.alias || [],
    redirect: analyzer1ndP9iNI4QMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue").then(m => m.default || m)
  },
  {
    name: analyzer1ndP9iNI4QMeta?.name ?? "national-targets-analyzer___en",
    path: analyzer1ndP9iNI4QMeta?.path ?? "/en/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    alias: analyzer1ndP9iNI4QMeta?.alias || [],
    redirect: analyzer1ndP9iNI4QMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue").then(m => m.default || m)
  },
  {
    name: analyzer1ndP9iNI4QMeta?.name ?? "national-targets-analyzer___fr",
    path: analyzer1ndP9iNI4QMeta?.path ?? "/fr/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    alias: analyzer1ndP9iNI4QMeta?.alias || [],
    redirect: analyzer1ndP9iNI4QMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue").then(m => m.default || m)
  },
  {
    name: analyzer1ndP9iNI4QMeta?.name ?? "national-targets-analyzer___es",
    path: analyzer1ndP9iNI4QMeta?.path ?? "/es/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    alias: analyzer1ndP9iNI4QMeta?.alias || [],
    redirect: analyzer1ndP9iNI4QMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue").then(m => m.default || m)
  },
  {
    name: analyzer1ndP9iNI4QMeta?.name ?? "national-targets-analyzer___ru",
    path: analyzer1ndP9iNI4QMeta?.path ?? "/ru/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    alias: analyzer1ndP9iNI4QMeta?.alias || [],
    redirect: analyzer1ndP9iNI4QMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue").then(m => m.default || m)
  },
  {
    name: analyzer1ndP9iNI4QMeta?.name ?? "national-targets-analyzer___zh",
    path: analyzer1ndP9iNI4QMeta?.path ?? "/zh/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    alias: analyzer1ndP9iNI4QMeta?.alias || [],
    redirect: analyzer1ndP9iNI4QMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue").then(m => m.default || m)
  },
  {
    name: indexb9IE1lLRN3Meta?.name ?? "national-targets___ar",
    path: indexb9IE1lLRN3Meta?.path ?? "/ar/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    alias: indexb9IE1lLRN3Meta?.alias || [],
    redirect: indexb9IE1lLRN3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9IE1lLRN3Meta?.name ?? "national-targets___en___default",
    path: indexb9IE1lLRN3Meta?.path ?? "/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    alias: indexb9IE1lLRN3Meta?.alias || [],
    redirect: indexb9IE1lLRN3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9IE1lLRN3Meta?.name ?? "national-targets___en",
    path: indexb9IE1lLRN3Meta?.path ?? "/en/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    alias: indexb9IE1lLRN3Meta?.alias || [],
    redirect: indexb9IE1lLRN3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9IE1lLRN3Meta?.name ?? "national-targets___fr",
    path: indexb9IE1lLRN3Meta?.path ?? "/fr/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    alias: indexb9IE1lLRN3Meta?.alias || [],
    redirect: indexb9IE1lLRN3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9IE1lLRN3Meta?.name ?? "national-targets___es",
    path: indexb9IE1lLRN3Meta?.path ?? "/es/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    alias: indexb9IE1lLRN3Meta?.alias || [],
    redirect: indexb9IE1lLRN3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9IE1lLRN3Meta?.name ?? "national-targets___ru",
    path: indexb9IE1lLRN3Meta?.path ?? "/ru/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    alias: indexb9IE1lLRN3Meta?.alias || [],
    redirect: indexb9IE1lLRN3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/index.vue").then(m => m.default || m)
  },
  {
    name: indexb9IE1lLRN3Meta?.name ?? "national-targets___zh",
    path: indexb9IE1lLRN3Meta?.path ?? "/zh/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    alias: indexb9IE1lLRN3Meta?.alias || [],
    redirect: indexb9IE1lLRN3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ6mjSzSz5lMeta?.name ?? "national-targets-my-country___ar",
    path: indexJ6mjSzSz5lMeta?.path ?? "/ar/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    alias: indexJ6mjSzSz5lMeta?.alias || [],
    redirect: indexJ6mjSzSz5lMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ6mjSzSz5lMeta?.name ?? "national-targets-my-country___en___default",
    path: indexJ6mjSzSz5lMeta?.path ?? "/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    alias: indexJ6mjSzSz5lMeta?.alias || [],
    redirect: indexJ6mjSzSz5lMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ6mjSzSz5lMeta?.name ?? "national-targets-my-country___en",
    path: indexJ6mjSzSz5lMeta?.path ?? "/en/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    alias: indexJ6mjSzSz5lMeta?.alias || [],
    redirect: indexJ6mjSzSz5lMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ6mjSzSz5lMeta?.name ?? "national-targets-my-country___fr",
    path: indexJ6mjSzSz5lMeta?.path ?? "/fr/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    alias: indexJ6mjSzSz5lMeta?.alias || [],
    redirect: indexJ6mjSzSz5lMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ6mjSzSz5lMeta?.name ?? "national-targets-my-country___es",
    path: indexJ6mjSzSz5lMeta?.path ?? "/es/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    alias: indexJ6mjSzSz5lMeta?.alias || [],
    redirect: indexJ6mjSzSz5lMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ6mjSzSz5lMeta?.name ?? "national-targets-my-country___ru",
    path: indexJ6mjSzSz5lMeta?.path ?? "/ru/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    alias: indexJ6mjSzSz5lMeta?.alias || [],
    redirect: indexJ6mjSzSz5lMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ6mjSzSz5lMeta?.name ?? "national-targets-my-country___zh",
    path: indexJ6mjSzSz5lMeta?.path ?? "/zh/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    alias: indexJ6mjSzSz5lMeta?.alias || [],
    redirect: indexJ6mjSzSz5lMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: editMqCXwCUPWOMeta?.name ?? "national-targets-my-country-part-1-identifier-edit___ar",
    path: editMqCXwCUPWOMeta?.path ?? "/ar/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    alias: editMqCXwCUPWOMeta?.alias || [],
    redirect: editMqCXwCUPWOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: editMqCXwCUPWOMeta?.name ?? "national-targets-my-country-part-1-identifier-edit___en___default",
    path: editMqCXwCUPWOMeta?.path ?? "/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    alias: editMqCXwCUPWOMeta?.alias || [],
    redirect: editMqCXwCUPWOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: editMqCXwCUPWOMeta?.name ?? "national-targets-my-country-part-1-identifier-edit___en",
    path: editMqCXwCUPWOMeta?.path ?? "/en/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    alias: editMqCXwCUPWOMeta?.alias || [],
    redirect: editMqCXwCUPWOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: editMqCXwCUPWOMeta?.name ?? "national-targets-my-country-part-1-identifier-edit___fr",
    path: editMqCXwCUPWOMeta?.path ?? "/fr/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    alias: editMqCXwCUPWOMeta?.alias || [],
    redirect: editMqCXwCUPWOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: editMqCXwCUPWOMeta?.name ?? "national-targets-my-country-part-1-identifier-edit___es",
    path: editMqCXwCUPWOMeta?.path ?? "/es/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    alias: editMqCXwCUPWOMeta?.alias || [],
    redirect: editMqCXwCUPWOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: editMqCXwCUPWOMeta?.name ?? "national-targets-my-country-part-1-identifier-edit___ru",
    path: editMqCXwCUPWOMeta?.path ?? "/ru/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    alias: editMqCXwCUPWOMeta?.alias || [],
    redirect: editMqCXwCUPWOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: editMqCXwCUPWOMeta?.name ?? "national-targets-my-country-part-1-identifier-edit___zh",
    path: editMqCXwCUPWOMeta?.path ?? "/zh/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    alias: editMqCXwCUPWOMeta?.alias || [],
    redirect: editMqCXwCUPWOMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: index8uiIbni5p0Meta?.name ?? "national-targets-my-country-part-1-identifier___ar",
    path: index8uiIbni5p0Meta?.path ?? "/ar/national-targets/my-country/part-1/:identifier()",
    meta: index8uiIbni5p0Meta || {},
    alias: index8uiIbni5p0Meta?.alias || [],
    redirect: index8uiIbni5p0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: index8uiIbni5p0Meta?.name ?? "national-targets-my-country-part-1-identifier___en___default",
    path: index8uiIbni5p0Meta?.path ?? "/national-targets/my-country/part-1/:identifier()",
    meta: index8uiIbni5p0Meta || {},
    alias: index8uiIbni5p0Meta?.alias || [],
    redirect: index8uiIbni5p0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: index8uiIbni5p0Meta?.name ?? "national-targets-my-country-part-1-identifier___en",
    path: index8uiIbni5p0Meta?.path ?? "/en/national-targets/my-country/part-1/:identifier()",
    meta: index8uiIbni5p0Meta || {},
    alias: index8uiIbni5p0Meta?.alias || [],
    redirect: index8uiIbni5p0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: index8uiIbni5p0Meta?.name ?? "national-targets-my-country-part-1-identifier___fr",
    path: index8uiIbni5p0Meta?.path ?? "/fr/national-targets/my-country/part-1/:identifier()",
    meta: index8uiIbni5p0Meta || {},
    alias: index8uiIbni5p0Meta?.alias || [],
    redirect: index8uiIbni5p0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: index8uiIbni5p0Meta?.name ?? "national-targets-my-country-part-1-identifier___es",
    path: index8uiIbni5p0Meta?.path ?? "/es/national-targets/my-country/part-1/:identifier()",
    meta: index8uiIbni5p0Meta || {},
    alias: index8uiIbni5p0Meta?.alias || [],
    redirect: index8uiIbni5p0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: index8uiIbni5p0Meta?.name ?? "national-targets-my-country-part-1-identifier___ru",
    path: index8uiIbni5p0Meta?.path ?? "/ru/national-targets/my-country/part-1/:identifier()",
    meta: index8uiIbni5p0Meta || {},
    alias: index8uiIbni5p0Meta?.alias || [],
    redirect: index8uiIbni5p0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: index8uiIbni5p0Meta?.name ?? "national-targets-my-country-part-1-identifier___zh",
    path: index8uiIbni5p0Meta?.path ?? "/zh/national-targets/my-country/part-1/:identifier()",
    meta: index8uiIbni5p0Meta || {},
    alias: index8uiIbni5p0Meta?.alias || [],
    redirect: index8uiIbni5p0Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: viewmYVEBfT2oEMeta?.name ?? "national-targets-my-country-part-1-identifier-view___ar",
    path: viewmYVEBfT2oEMeta?.path ?? "/ar/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    alias: viewmYVEBfT2oEMeta?.alias || [],
    redirect: viewmYVEBfT2oEMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewmYVEBfT2oEMeta?.name ?? "national-targets-my-country-part-1-identifier-view___en___default",
    path: viewmYVEBfT2oEMeta?.path ?? "/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    alias: viewmYVEBfT2oEMeta?.alias || [],
    redirect: viewmYVEBfT2oEMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewmYVEBfT2oEMeta?.name ?? "national-targets-my-country-part-1-identifier-view___en",
    path: viewmYVEBfT2oEMeta?.path ?? "/en/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    alias: viewmYVEBfT2oEMeta?.alias || [],
    redirect: viewmYVEBfT2oEMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewmYVEBfT2oEMeta?.name ?? "national-targets-my-country-part-1-identifier-view___fr",
    path: viewmYVEBfT2oEMeta?.path ?? "/fr/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    alias: viewmYVEBfT2oEMeta?.alias || [],
    redirect: viewmYVEBfT2oEMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewmYVEBfT2oEMeta?.name ?? "national-targets-my-country-part-1-identifier-view___es",
    path: viewmYVEBfT2oEMeta?.path ?? "/es/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    alias: viewmYVEBfT2oEMeta?.alias || [],
    redirect: viewmYVEBfT2oEMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewmYVEBfT2oEMeta?.name ?? "national-targets-my-country-part-1-identifier-view___ru",
    path: viewmYVEBfT2oEMeta?.path ?? "/ru/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    alias: viewmYVEBfT2oEMeta?.alias || [],
    redirect: viewmYVEBfT2oEMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewmYVEBfT2oEMeta?.name ?? "national-targets-my-country-part-1-identifier-view___zh",
    path: viewmYVEBfT2oEMeta?.path ?? "/zh/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    alias: viewmYVEBfT2oEMeta?.alias || [],
    redirect: viewmYVEBfT2oEMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: indexZ753QJASLnMeta?.name ?? "national-targets-my-country-part-1___ar",
    path: indexZ753QJASLnMeta?.path ?? "/ar/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    alias: indexZ753QJASLnMeta?.alias || [],
    redirect: indexZ753QJASLnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ753QJASLnMeta?.name ?? "national-targets-my-country-part-1___en___default",
    path: indexZ753QJASLnMeta?.path ?? "/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    alias: indexZ753QJASLnMeta?.alias || [],
    redirect: indexZ753QJASLnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ753QJASLnMeta?.name ?? "national-targets-my-country-part-1___en",
    path: indexZ753QJASLnMeta?.path ?? "/en/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    alias: indexZ753QJASLnMeta?.alias || [],
    redirect: indexZ753QJASLnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ753QJASLnMeta?.name ?? "national-targets-my-country-part-1___fr",
    path: indexZ753QJASLnMeta?.path ?? "/fr/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    alias: indexZ753QJASLnMeta?.alias || [],
    redirect: indexZ753QJASLnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ753QJASLnMeta?.name ?? "national-targets-my-country-part-1___es",
    path: indexZ753QJASLnMeta?.path ?? "/es/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    alias: indexZ753QJASLnMeta?.alias || [],
    redirect: indexZ753QJASLnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ753QJASLnMeta?.name ?? "national-targets-my-country-part-1___ru",
    path: indexZ753QJASLnMeta?.path ?? "/ru/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    alias: indexZ753QJASLnMeta?.alias || [],
    redirect: indexZ753QJASLnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ753QJASLnMeta?.name ?? "national-targets-my-country-part-1___zh",
    path: indexZ753QJASLnMeta?.path ?? "/zh/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    alias: indexZ753QJASLnMeta?.alias || [],
    redirect: indexZ753QJASLnMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue").then(m => m.default || m)
  },
  {
    name: newoyNds3oRk3Meta?.name ?? "national-targets-my-country-part-1-new___ar",
    path: newoyNds3oRk3Meta?.path ?? "/ar/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    alias: newoyNds3oRk3Meta?.alias || [],
    redirect: newoyNds3oRk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue").then(m => m.default || m)
  },
  {
    name: newoyNds3oRk3Meta?.name ?? "national-targets-my-country-part-1-new___en___default",
    path: newoyNds3oRk3Meta?.path ?? "/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    alias: newoyNds3oRk3Meta?.alias || [],
    redirect: newoyNds3oRk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue").then(m => m.default || m)
  },
  {
    name: newoyNds3oRk3Meta?.name ?? "national-targets-my-country-part-1-new___en",
    path: newoyNds3oRk3Meta?.path ?? "/en/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    alias: newoyNds3oRk3Meta?.alias || [],
    redirect: newoyNds3oRk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue").then(m => m.default || m)
  },
  {
    name: newoyNds3oRk3Meta?.name ?? "national-targets-my-country-part-1-new___fr",
    path: newoyNds3oRk3Meta?.path ?? "/fr/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    alias: newoyNds3oRk3Meta?.alias || [],
    redirect: newoyNds3oRk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue").then(m => m.default || m)
  },
  {
    name: newoyNds3oRk3Meta?.name ?? "national-targets-my-country-part-1-new___es",
    path: newoyNds3oRk3Meta?.path ?? "/es/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    alias: newoyNds3oRk3Meta?.alias || [],
    redirect: newoyNds3oRk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue").then(m => m.default || m)
  },
  {
    name: newoyNds3oRk3Meta?.name ?? "national-targets-my-country-part-1-new___ru",
    path: newoyNds3oRk3Meta?.path ?? "/ru/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    alias: newoyNds3oRk3Meta?.alias || [],
    redirect: newoyNds3oRk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue").then(m => m.default || m)
  },
  {
    name: newoyNds3oRk3Meta?.name ?? "national-targets-my-country-part-1-new___zh",
    path: newoyNds3oRk3Meta?.path ?? "/zh/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    alias: newoyNds3oRk3Meta?.alias || [],
    redirect: newoyNds3oRk3Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue").then(m => m.default || m)
  },
  {
    name: edit2QYpjz5YoQMeta?.name ?? "national-targets-my-country-part-2-identifier-edit___ar",
    path: edit2QYpjz5YoQMeta?.path ?? "/ar/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    alias: edit2QYpjz5YoQMeta?.alias || [],
    redirect: edit2QYpjz5YoQMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2QYpjz5YoQMeta?.name ?? "national-targets-my-country-part-2-identifier-edit___en___default",
    path: edit2QYpjz5YoQMeta?.path ?? "/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    alias: edit2QYpjz5YoQMeta?.alias || [],
    redirect: edit2QYpjz5YoQMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2QYpjz5YoQMeta?.name ?? "national-targets-my-country-part-2-identifier-edit___en",
    path: edit2QYpjz5YoQMeta?.path ?? "/en/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    alias: edit2QYpjz5YoQMeta?.alias || [],
    redirect: edit2QYpjz5YoQMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2QYpjz5YoQMeta?.name ?? "national-targets-my-country-part-2-identifier-edit___fr",
    path: edit2QYpjz5YoQMeta?.path ?? "/fr/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    alias: edit2QYpjz5YoQMeta?.alias || [],
    redirect: edit2QYpjz5YoQMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2QYpjz5YoQMeta?.name ?? "national-targets-my-country-part-2-identifier-edit___es",
    path: edit2QYpjz5YoQMeta?.path ?? "/es/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    alias: edit2QYpjz5YoQMeta?.alias || [],
    redirect: edit2QYpjz5YoQMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2QYpjz5YoQMeta?.name ?? "national-targets-my-country-part-2-identifier-edit___ru",
    path: edit2QYpjz5YoQMeta?.path ?? "/ru/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    alias: edit2QYpjz5YoQMeta?.alias || [],
    redirect: edit2QYpjz5YoQMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit2QYpjz5YoQMeta?.name ?? "national-targets-my-country-part-2-identifier-edit___zh",
    path: edit2QYpjz5YoQMeta?.path ?? "/zh/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    alias: edit2QYpjz5YoQMeta?.alias || [],
    redirect: edit2QYpjz5YoQMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexwnpy75vGw7Meta?.name ?? "national-targets-my-country-part-2-identifier___ar",
    path: indexwnpy75vGw7Meta?.path ?? "/ar/national-targets/my-country/part-2/:identifier()",
    meta: indexwnpy75vGw7Meta || {},
    alias: indexwnpy75vGw7Meta?.alias || [],
    redirect: indexwnpy75vGw7Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnpy75vGw7Meta?.name ?? "national-targets-my-country-part-2-identifier___en___default",
    path: indexwnpy75vGw7Meta?.path ?? "/national-targets/my-country/part-2/:identifier()",
    meta: indexwnpy75vGw7Meta || {},
    alias: indexwnpy75vGw7Meta?.alias || [],
    redirect: indexwnpy75vGw7Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnpy75vGw7Meta?.name ?? "national-targets-my-country-part-2-identifier___en",
    path: indexwnpy75vGw7Meta?.path ?? "/en/national-targets/my-country/part-2/:identifier()",
    meta: indexwnpy75vGw7Meta || {},
    alias: indexwnpy75vGw7Meta?.alias || [],
    redirect: indexwnpy75vGw7Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnpy75vGw7Meta?.name ?? "national-targets-my-country-part-2-identifier___fr",
    path: indexwnpy75vGw7Meta?.path ?? "/fr/national-targets/my-country/part-2/:identifier()",
    meta: indexwnpy75vGw7Meta || {},
    alias: indexwnpy75vGw7Meta?.alias || [],
    redirect: indexwnpy75vGw7Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnpy75vGw7Meta?.name ?? "national-targets-my-country-part-2-identifier___es",
    path: indexwnpy75vGw7Meta?.path ?? "/es/national-targets/my-country/part-2/:identifier()",
    meta: indexwnpy75vGw7Meta || {},
    alias: indexwnpy75vGw7Meta?.alias || [],
    redirect: indexwnpy75vGw7Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnpy75vGw7Meta?.name ?? "national-targets-my-country-part-2-identifier___ru",
    path: indexwnpy75vGw7Meta?.path ?? "/ru/national-targets/my-country/part-2/:identifier()",
    meta: indexwnpy75vGw7Meta || {},
    alias: indexwnpy75vGw7Meta?.alias || [],
    redirect: indexwnpy75vGw7Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnpy75vGw7Meta?.name ?? "national-targets-my-country-part-2-identifier___zh",
    path: indexwnpy75vGw7Meta?.path ?? "/zh/national-targets/my-country/part-2/:identifier()",
    meta: indexwnpy75vGw7Meta || {},
    alias: indexwnpy75vGw7Meta?.alias || [],
    redirect: indexwnpy75vGw7Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: viewqURcoCkj38Meta?.name ?? "national-targets-my-country-part-2-identifier-view___ar",
    path: viewqURcoCkj38Meta?.path ?? "/ar/national-targets/my-country/part-2/:identifier()/view",
    meta: viewqURcoCkj38Meta || {},
    alias: viewqURcoCkj38Meta?.alias || [],
    redirect: viewqURcoCkj38Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewqURcoCkj38Meta?.name ?? "national-targets-my-country-part-2-identifier-view___en___default",
    path: viewqURcoCkj38Meta?.path ?? "/national-targets/my-country/part-2/:identifier()/view",
    meta: viewqURcoCkj38Meta || {},
    alias: viewqURcoCkj38Meta?.alias || [],
    redirect: viewqURcoCkj38Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewqURcoCkj38Meta?.name ?? "national-targets-my-country-part-2-identifier-view___en",
    path: viewqURcoCkj38Meta?.path ?? "/en/national-targets/my-country/part-2/:identifier()/view",
    meta: viewqURcoCkj38Meta || {},
    alias: viewqURcoCkj38Meta?.alias || [],
    redirect: viewqURcoCkj38Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewqURcoCkj38Meta?.name ?? "national-targets-my-country-part-2-identifier-view___fr",
    path: viewqURcoCkj38Meta?.path ?? "/fr/national-targets/my-country/part-2/:identifier()/view",
    meta: viewqURcoCkj38Meta || {},
    alias: viewqURcoCkj38Meta?.alias || [],
    redirect: viewqURcoCkj38Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewqURcoCkj38Meta?.name ?? "national-targets-my-country-part-2-identifier-view___es",
    path: viewqURcoCkj38Meta?.path ?? "/es/national-targets/my-country/part-2/:identifier()/view",
    meta: viewqURcoCkj38Meta || {},
    alias: viewqURcoCkj38Meta?.alias || [],
    redirect: viewqURcoCkj38Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewqURcoCkj38Meta?.name ?? "national-targets-my-country-part-2-identifier-view___ru",
    path: viewqURcoCkj38Meta?.path ?? "/ru/national-targets/my-country/part-2/:identifier()/view",
    meta: viewqURcoCkj38Meta || {},
    alias: viewqURcoCkj38Meta?.alias || [],
    redirect: viewqURcoCkj38Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewqURcoCkj38Meta?.name ?? "national-targets-my-country-part-2-identifier-view___zh",
    path: viewqURcoCkj38Meta?.path ?? "/zh/national-targets/my-country/part-2/:identifier()/view",
    meta: viewqURcoCkj38Meta || {},
    alias: viewqURcoCkj38Meta?.alias || [],
    redirect: viewqURcoCkj38Meta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: indexOJUnEL0XnXMeta?.name ?? "national-targets-my-country-part-2___ar",
    path: indexOJUnEL0XnXMeta?.path ?? "/ar/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    alias: indexOJUnEL0XnXMeta?.alias || [],
    redirect: indexOJUnEL0XnXMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexOJUnEL0XnXMeta?.name ?? "national-targets-my-country-part-2___en___default",
    path: indexOJUnEL0XnXMeta?.path ?? "/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    alias: indexOJUnEL0XnXMeta?.alias || [],
    redirect: indexOJUnEL0XnXMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexOJUnEL0XnXMeta?.name ?? "national-targets-my-country-part-2___en",
    path: indexOJUnEL0XnXMeta?.path ?? "/en/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    alias: indexOJUnEL0XnXMeta?.alias || [],
    redirect: indexOJUnEL0XnXMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexOJUnEL0XnXMeta?.name ?? "national-targets-my-country-part-2___fr",
    path: indexOJUnEL0XnXMeta?.path ?? "/fr/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    alias: indexOJUnEL0XnXMeta?.alias || [],
    redirect: indexOJUnEL0XnXMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexOJUnEL0XnXMeta?.name ?? "national-targets-my-country-part-2___es",
    path: indexOJUnEL0XnXMeta?.path ?? "/es/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    alias: indexOJUnEL0XnXMeta?.alias || [],
    redirect: indexOJUnEL0XnXMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexOJUnEL0XnXMeta?.name ?? "national-targets-my-country-part-2___ru",
    path: indexOJUnEL0XnXMeta?.path ?? "/ru/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    alias: indexOJUnEL0XnXMeta?.alias || [],
    redirect: indexOJUnEL0XnXMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexOJUnEL0XnXMeta?.name ?? "national-targets-my-country-part-2___zh",
    path: indexOJUnEL0XnXMeta?.path ?? "/zh/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    alias: indexOJUnEL0XnXMeta?.alias || [],
    redirect: indexOJUnEL0XnXMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue").then(m => m.default || m)
  },
  {
    name: newMS6sxO9R2PMeta?.name ?? "national-targets-my-country-part-2-new___ar",
    path: newMS6sxO9R2PMeta?.path ?? "/ar/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    alias: newMS6sxO9R2PMeta?.alias || [],
    redirect: newMS6sxO9R2PMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue").then(m => m.default || m)
  },
  {
    name: newMS6sxO9R2PMeta?.name ?? "national-targets-my-country-part-2-new___en___default",
    path: newMS6sxO9R2PMeta?.path ?? "/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    alias: newMS6sxO9R2PMeta?.alias || [],
    redirect: newMS6sxO9R2PMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue").then(m => m.default || m)
  },
  {
    name: newMS6sxO9R2PMeta?.name ?? "national-targets-my-country-part-2-new___en",
    path: newMS6sxO9R2PMeta?.path ?? "/en/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    alias: newMS6sxO9R2PMeta?.alias || [],
    redirect: newMS6sxO9R2PMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue").then(m => m.default || m)
  },
  {
    name: newMS6sxO9R2PMeta?.name ?? "national-targets-my-country-part-2-new___fr",
    path: newMS6sxO9R2PMeta?.path ?? "/fr/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    alias: newMS6sxO9R2PMeta?.alias || [],
    redirect: newMS6sxO9R2PMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue").then(m => m.default || m)
  },
  {
    name: newMS6sxO9R2PMeta?.name ?? "national-targets-my-country-part-2-new___es",
    path: newMS6sxO9R2PMeta?.path ?? "/es/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    alias: newMS6sxO9R2PMeta?.alias || [],
    redirect: newMS6sxO9R2PMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue").then(m => m.default || m)
  },
  {
    name: newMS6sxO9R2PMeta?.name ?? "national-targets-my-country-part-2-new___ru",
    path: newMS6sxO9R2PMeta?.path ?? "/ru/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    alias: newMS6sxO9R2PMeta?.alias || [],
    redirect: newMS6sxO9R2PMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue").then(m => m.default || m)
  },
  {
    name: newMS6sxO9R2PMeta?.name ?? "national-targets-my-country-part-2-new___zh",
    path: newMS6sxO9R2PMeta?.path ?? "/zh/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    alias: newMS6sxO9R2PMeta?.alias || [],
    redirect: newMS6sxO9R2PMeta?.redirect,
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue").then(m => m.default || m)
  },
  {
    name: indexyyK4E4AyLJMeta?.name ?? "nbsaps___ar",
    path: indexyyK4E4AyLJMeta?.path ?? "/ar/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    alias: indexyyK4E4AyLJMeta?.alias || [],
    redirect: indexyyK4E4AyLJMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyK4E4AyLJMeta?.name ?? "nbsaps___en___default",
    path: indexyyK4E4AyLJMeta?.path ?? "/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    alias: indexyyK4E4AyLJMeta?.alias || [],
    redirect: indexyyK4E4AyLJMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyK4E4AyLJMeta?.name ?? "nbsaps___en",
    path: indexyyK4E4AyLJMeta?.path ?? "/en/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    alias: indexyyK4E4AyLJMeta?.alias || [],
    redirect: indexyyK4E4AyLJMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyK4E4AyLJMeta?.name ?? "nbsaps___fr",
    path: indexyyK4E4AyLJMeta?.path ?? "/fr/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    alias: indexyyK4E4AyLJMeta?.alias || [],
    redirect: indexyyK4E4AyLJMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyK4E4AyLJMeta?.name ?? "nbsaps___es",
    path: indexyyK4E4AyLJMeta?.path ?? "/es/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    alias: indexyyK4E4AyLJMeta?.alias || [],
    redirect: indexyyK4E4AyLJMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyK4E4AyLJMeta?.name ?? "nbsaps___ru",
    path: indexyyK4E4AyLJMeta?.path ?? "/ru/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    alias: indexyyK4E4AyLJMeta?.alias || [],
    redirect: indexyyK4E4AyLJMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexyyK4E4AyLJMeta?.name ?? "nbsaps___zh",
    path: indexyyK4E4AyLJMeta?.path ?? "/zh/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    alias: indexyyK4E4AyLJMeta?.alias || [],
    redirect: indexyyK4E4AyLJMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/index.vue").then(m => m.default || m)
  },
  {
    name: edit8Y1LxXRh7vMeta?.name ?? "nbsaps-my-country-identifier-edit___ar",
    path: edit8Y1LxXRh7vMeta?.path ?? "/ar/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    alias: edit8Y1LxXRh7vMeta?.alias || [],
    redirect: edit8Y1LxXRh7vMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit8Y1LxXRh7vMeta?.name ?? "nbsaps-my-country-identifier-edit___en___default",
    path: edit8Y1LxXRh7vMeta?.path ?? "/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    alias: edit8Y1LxXRh7vMeta?.alias || [],
    redirect: edit8Y1LxXRh7vMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit8Y1LxXRh7vMeta?.name ?? "nbsaps-my-country-identifier-edit___en",
    path: edit8Y1LxXRh7vMeta?.path ?? "/en/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    alias: edit8Y1LxXRh7vMeta?.alias || [],
    redirect: edit8Y1LxXRh7vMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit8Y1LxXRh7vMeta?.name ?? "nbsaps-my-country-identifier-edit___fr",
    path: edit8Y1LxXRh7vMeta?.path ?? "/fr/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    alias: edit8Y1LxXRh7vMeta?.alias || [],
    redirect: edit8Y1LxXRh7vMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit8Y1LxXRh7vMeta?.name ?? "nbsaps-my-country-identifier-edit___es",
    path: edit8Y1LxXRh7vMeta?.path ?? "/es/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    alias: edit8Y1LxXRh7vMeta?.alias || [],
    redirect: edit8Y1LxXRh7vMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit8Y1LxXRh7vMeta?.name ?? "nbsaps-my-country-identifier-edit___ru",
    path: edit8Y1LxXRh7vMeta?.path ?? "/ru/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    alias: edit8Y1LxXRh7vMeta?.alias || [],
    redirect: edit8Y1LxXRh7vMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit8Y1LxXRh7vMeta?.name ?? "nbsaps-my-country-identifier-edit___zh",
    path: edit8Y1LxXRh7vMeta?.path ?? "/zh/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    alias: edit8Y1LxXRh7vMeta?.alias || [],
    redirect: edit8Y1LxXRh7vMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexlp6EFoeuohMeta?.name ?? "nbsaps-my-country-identifier___ar",
    path: indexlp6EFoeuohMeta?.path ?? "/ar/nbsaps/my-country/:identifier()",
    meta: indexlp6EFoeuohMeta || {},
    alias: indexlp6EFoeuohMeta?.alias || [],
    redirect: indexlp6EFoeuohMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlp6EFoeuohMeta?.name ?? "nbsaps-my-country-identifier___en___default",
    path: indexlp6EFoeuohMeta?.path ?? "/nbsaps/my-country/:identifier()",
    meta: indexlp6EFoeuohMeta || {},
    alias: indexlp6EFoeuohMeta?.alias || [],
    redirect: indexlp6EFoeuohMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlp6EFoeuohMeta?.name ?? "nbsaps-my-country-identifier___en",
    path: indexlp6EFoeuohMeta?.path ?? "/en/nbsaps/my-country/:identifier()",
    meta: indexlp6EFoeuohMeta || {},
    alias: indexlp6EFoeuohMeta?.alias || [],
    redirect: indexlp6EFoeuohMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlp6EFoeuohMeta?.name ?? "nbsaps-my-country-identifier___fr",
    path: indexlp6EFoeuohMeta?.path ?? "/fr/nbsaps/my-country/:identifier()",
    meta: indexlp6EFoeuohMeta || {},
    alias: indexlp6EFoeuohMeta?.alias || [],
    redirect: indexlp6EFoeuohMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlp6EFoeuohMeta?.name ?? "nbsaps-my-country-identifier___es",
    path: indexlp6EFoeuohMeta?.path ?? "/es/nbsaps/my-country/:identifier()",
    meta: indexlp6EFoeuohMeta || {},
    alias: indexlp6EFoeuohMeta?.alias || [],
    redirect: indexlp6EFoeuohMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlp6EFoeuohMeta?.name ?? "nbsaps-my-country-identifier___ru",
    path: indexlp6EFoeuohMeta?.path ?? "/ru/nbsaps/my-country/:identifier()",
    meta: indexlp6EFoeuohMeta || {},
    alias: indexlp6EFoeuohMeta?.alias || [],
    redirect: indexlp6EFoeuohMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlp6EFoeuohMeta?.name ?? "nbsaps-my-country-identifier___zh",
    path: indexlp6EFoeuohMeta?.path ?? "/zh/nbsaps/my-country/:identifier()",
    meta: indexlp6EFoeuohMeta || {},
    alias: indexlp6EFoeuohMeta?.alias || [],
    redirect: indexlp6EFoeuohMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: viewNKttmkdBYAMeta?.name ?? "nbsaps-my-country-identifier-view___ar",
    path: viewNKttmkdBYAMeta?.path ?? "/ar/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    alias: viewNKttmkdBYAMeta?.alias || [],
    redirect: viewNKttmkdBYAMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewNKttmkdBYAMeta?.name ?? "nbsaps-my-country-identifier-view___en___default",
    path: viewNKttmkdBYAMeta?.path ?? "/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    alias: viewNKttmkdBYAMeta?.alias || [],
    redirect: viewNKttmkdBYAMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewNKttmkdBYAMeta?.name ?? "nbsaps-my-country-identifier-view___en",
    path: viewNKttmkdBYAMeta?.path ?? "/en/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    alias: viewNKttmkdBYAMeta?.alias || [],
    redirect: viewNKttmkdBYAMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewNKttmkdBYAMeta?.name ?? "nbsaps-my-country-identifier-view___fr",
    path: viewNKttmkdBYAMeta?.path ?? "/fr/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    alias: viewNKttmkdBYAMeta?.alias || [],
    redirect: viewNKttmkdBYAMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewNKttmkdBYAMeta?.name ?? "nbsaps-my-country-identifier-view___es",
    path: viewNKttmkdBYAMeta?.path ?? "/es/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    alias: viewNKttmkdBYAMeta?.alias || [],
    redirect: viewNKttmkdBYAMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewNKttmkdBYAMeta?.name ?? "nbsaps-my-country-identifier-view___ru",
    path: viewNKttmkdBYAMeta?.path ?? "/ru/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    alias: viewNKttmkdBYAMeta?.alias || [],
    redirect: viewNKttmkdBYAMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: viewNKttmkdBYAMeta?.name ?? "nbsaps-my-country-identifier-view___zh",
    path: viewNKttmkdBYAMeta?.path ?? "/zh/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    alias: viewNKttmkdBYAMeta?.alias || [],
    redirect: viewNKttmkdBYAMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue").then(m => m.default || m)
  },
  {
    name: indexpMPnSS42HFMeta?.name ?? "nbsaps-my-country___ar",
    path: indexpMPnSS42HFMeta?.path ?? "/ar/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    alias: indexpMPnSS42HFMeta?.alias || [],
    redirect: indexpMPnSS42HFMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexpMPnSS42HFMeta?.name ?? "nbsaps-my-country___en___default",
    path: indexpMPnSS42HFMeta?.path ?? "/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    alias: indexpMPnSS42HFMeta?.alias || [],
    redirect: indexpMPnSS42HFMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexpMPnSS42HFMeta?.name ?? "nbsaps-my-country___en",
    path: indexpMPnSS42HFMeta?.path ?? "/en/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    alias: indexpMPnSS42HFMeta?.alias || [],
    redirect: indexpMPnSS42HFMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexpMPnSS42HFMeta?.name ?? "nbsaps-my-country___fr",
    path: indexpMPnSS42HFMeta?.path ?? "/fr/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    alias: indexpMPnSS42HFMeta?.alias || [],
    redirect: indexpMPnSS42HFMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexpMPnSS42HFMeta?.name ?? "nbsaps-my-country___es",
    path: indexpMPnSS42HFMeta?.path ?? "/es/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    alias: indexpMPnSS42HFMeta?.alias || [],
    redirect: indexpMPnSS42HFMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexpMPnSS42HFMeta?.name ?? "nbsaps-my-country___ru",
    path: indexpMPnSS42HFMeta?.path ?? "/ru/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    alias: indexpMPnSS42HFMeta?.alias || [],
    redirect: indexpMPnSS42HFMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: indexpMPnSS42HFMeta?.name ?? "nbsaps-my-country___zh",
    path: indexpMPnSS42HFMeta?.path ?? "/zh/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    alias: indexpMPnSS42HFMeta?.alias || [],
    redirect: indexpMPnSS42HFMeta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue").then(m => m.default || m)
  },
  {
    name: newECpcWBzEP0Meta?.name ?? "nbsaps-my-country-new___ar",
    path: newECpcWBzEP0Meta?.path ?? "/ar/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    alias: newECpcWBzEP0Meta?.alias || [],
    redirect: newECpcWBzEP0Meta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue").then(m => m.default || m)
  },
  {
    name: newECpcWBzEP0Meta?.name ?? "nbsaps-my-country-new___en___default",
    path: newECpcWBzEP0Meta?.path ?? "/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    alias: newECpcWBzEP0Meta?.alias || [],
    redirect: newECpcWBzEP0Meta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue").then(m => m.default || m)
  },
  {
    name: newECpcWBzEP0Meta?.name ?? "nbsaps-my-country-new___en",
    path: newECpcWBzEP0Meta?.path ?? "/en/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    alias: newECpcWBzEP0Meta?.alias || [],
    redirect: newECpcWBzEP0Meta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue").then(m => m.default || m)
  },
  {
    name: newECpcWBzEP0Meta?.name ?? "nbsaps-my-country-new___fr",
    path: newECpcWBzEP0Meta?.path ?? "/fr/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    alias: newECpcWBzEP0Meta?.alias || [],
    redirect: newECpcWBzEP0Meta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue").then(m => m.default || m)
  },
  {
    name: newECpcWBzEP0Meta?.name ?? "nbsaps-my-country-new___es",
    path: newECpcWBzEP0Meta?.path ?? "/es/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    alias: newECpcWBzEP0Meta?.alias || [],
    redirect: newECpcWBzEP0Meta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue").then(m => m.default || m)
  },
  {
    name: newECpcWBzEP0Meta?.name ?? "nbsaps-my-country-new___ru",
    path: newECpcWBzEP0Meta?.path ?? "/ru/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    alias: newECpcWBzEP0Meta?.alias || [],
    redirect: newECpcWBzEP0Meta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue").then(m => m.default || m)
  },
  {
    name: newECpcWBzEP0Meta?.name ?? "nbsaps-my-country-new___zh",
    path: newECpcWBzEP0Meta?.path ?? "/zh/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    alias: newECpcWBzEP0Meta?.alias || [],
    redirect: newECpcWBzEP0Meta?.redirect,
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93DLiRS5ctyCMeta?.name ?? "search-identifier___ar",
    path: _91identifier_93DLiRS5ctyCMeta?.path ?? "/ar/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    alias: _91identifier_93DLiRS5ctyCMeta?.alias || [],
    redirect: _91identifier_93DLiRS5ctyCMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93DLiRS5ctyCMeta?.name ?? "search-identifier___en___default",
    path: _91identifier_93DLiRS5ctyCMeta?.path ?? "/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    alias: _91identifier_93DLiRS5ctyCMeta?.alias || [],
    redirect: _91identifier_93DLiRS5ctyCMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93DLiRS5ctyCMeta?.name ?? "search-identifier___en",
    path: _91identifier_93DLiRS5ctyCMeta?.path ?? "/en/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    alias: _91identifier_93DLiRS5ctyCMeta?.alias || [],
    redirect: _91identifier_93DLiRS5ctyCMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93DLiRS5ctyCMeta?.name ?? "search-identifier___fr",
    path: _91identifier_93DLiRS5ctyCMeta?.path ?? "/fr/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    alias: _91identifier_93DLiRS5ctyCMeta?.alias || [],
    redirect: _91identifier_93DLiRS5ctyCMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93DLiRS5ctyCMeta?.name ?? "search-identifier___es",
    path: _91identifier_93DLiRS5ctyCMeta?.path ?? "/es/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    alias: _91identifier_93DLiRS5ctyCMeta?.alias || [],
    redirect: _91identifier_93DLiRS5ctyCMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93DLiRS5ctyCMeta?.name ?? "search-identifier___ru",
    path: _91identifier_93DLiRS5ctyCMeta?.path ?? "/ru/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    alias: _91identifier_93DLiRS5ctyCMeta?.alias || [],
    redirect: _91identifier_93DLiRS5ctyCMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93DLiRS5ctyCMeta?.name ?? "search-identifier___zh",
    path: _91identifier_93DLiRS5ctyCMeta?.path ?? "/zh/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    alias: _91identifier_93DLiRS5ctyCMeta?.alias || [],
    redirect: _91identifier_93DLiRS5ctyCMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/[identifier].vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search___ar",
    path: indexUzt6YGbMnbMeta?.path ?? "/ar/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search___en___default",
    path: indexUzt6YGbMnbMeta?.path ?? "/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search___en",
    path: indexUzt6YGbMnbMeta?.path ?? "/en/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search___fr",
    path: indexUzt6YGbMnbMeta?.path ?? "/fr/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search___es",
    path: indexUzt6YGbMnbMeta?.path ?? "/es/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search___ru",
    path: indexUzt6YGbMnbMeta?.path ?? "/ru/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexUzt6YGbMnbMeta?.name ?? "search___zh",
    path: indexUzt6YGbMnbMeta?.path ?? "/zh/search",
    meta: indexUzt6YGbMnbMeta || {},
    alias: indexUzt6YGbMnbMeta?.alias || [],
    redirect: indexUzt6YGbMnbMeta?.redirect,
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93reiPSm5fFaMeta?.name ?? "taxonomy-gbf-identifier___ar",
    path: _91identifier_93reiPSm5fFaMeta?.path ?? "/ar/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    alias: _91identifier_93reiPSm5fFaMeta?.alias || [],
    redirect: _91identifier_93reiPSm5fFaMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93reiPSm5fFaMeta?.name ?? "taxonomy-gbf-identifier___en___default",
    path: _91identifier_93reiPSm5fFaMeta?.path ?? "/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    alias: _91identifier_93reiPSm5fFaMeta?.alias || [],
    redirect: _91identifier_93reiPSm5fFaMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93reiPSm5fFaMeta?.name ?? "taxonomy-gbf-identifier___en",
    path: _91identifier_93reiPSm5fFaMeta?.path ?? "/en/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    alias: _91identifier_93reiPSm5fFaMeta?.alias || [],
    redirect: _91identifier_93reiPSm5fFaMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93reiPSm5fFaMeta?.name ?? "taxonomy-gbf-identifier___fr",
    path: _91identifier_93reiPSm5fFaMeta?.path ?? "/fr/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    alias: _91identifier_93reiPSm5fFaMeta?.alias || [],
    redirect: _91identifier_93reiPSm5fFaMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93reiPSm5fFaMeta?.name ?? "taxonomy-gbf-identifier___es",
    path: _91identifier_93reiPSm5fFaMeta?.path ?? "/es/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    alias: _91identifier_93reiPSm5fFaMeta?.alias || [],
    redirect: _91identifier_93reiPSm5fFaMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93reiPSm5fFaMeta?.name ?? "taxonomy-gbf-identifier___ru",
    path: _91identifier_93reiPSm5fFaMeta?.path ?? "/ru/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    alias: _91identifier_93reiPSm5fFaMeta?.alias || [],
    redirect: _91identifier_93reiPSm5fFaMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93reiPSm5fFaMeta?.name ?? "taxonomy-gbf-identifier___zh",
    path: _91identifier_93reiPSm5fFaMeta?.path ?? "/zh/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    alias: _91identifier_93reiPSm5fFaMeta?.alias || [],
    redirect: _91identifier_93reiPSm5fFaMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93jWZnRp8Hh0Meta?.name ?? "taxonomy-gbf-target-identifier___ar",
    path: _91identifier_93jWZnRp8Hh0Meta?.path ?? "/ar/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    alias: _91identifier_93jWZnRp8Hh0Meta?.alias || [],
    redirect: _91identifier_93jWZnRp8Hh0Meta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93jWZnRp8Hh0Meta?.name ?? "taxonomy-gbf-target-identifier___en___default",
    path: _91identifier_93jWZnRp8Hh0Meta?.path ?? "/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    alias: _91identifier_93jWZnRp8Hh0Meta?.alias || [],
    redirect: _91identifier_93jWZnRp8Hh0Meta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93jWZnRp8Hh0Meta?.name ?? "taxonomy-gbf-target-identifier___en",
    path: _91identifier_93jWZnRp8Hh0Meta?.path ?? "/en/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    alias: _91identifier_93jWZnRp8Hh0Meta?.alias || [],
    redirect: _91identifier_93jWZnRp8Hh0Meta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93jWZnRp8Hh0Meta?.name ?? "taxonomy-gbf-target-identifier___fr",
    path: _91identifier_93jWZnRp8Hh0Meta?.path ?? "/fr/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    alias: _91identifier_93jWZnRp8Hh0Meta?.alias || [],
    redirect: _91identifier_93jWZnRp8Hh0Meta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93jWZnRp8Hh0Meta?.name ?? "taxonomy-gbf-target-identifier___es",
    path: _91identifier_93jWZnRp8Hh0Meta?.path ?? "/es/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    alias: _91identifier_93jWZnRp8Hh0Meta?.alias || [],
    redirect: _91identifier_93jWZnRp8Hh0Meta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93jWZnRp8Hh0Meta?.name ?? "taxonomy-gbf-target-identifier___ru",
    path: _91identifier_93jWZnRp8Hh0Meta?.path ?? "/ru/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    alias: _91identifier_93jWZnRp8Hh0Meta?.alias || [],
    redirect: _91identifier_93jWZnRp8Hh0Meta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: _91identifier_93jWZnRp8Hh0Meta?.name ?? "taxonomy-gbf-target-identifier___zh",
    path: _91identifier_93jWZnRp8Hh0Meta?.path ?? "/zh/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    alias: _91identifier_93jWZnRp8Hh0Meta?.alias || [],
    redirect: _91identifier_93jWZnRp8Hh0Meta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: indexrSB8j5cgCBMeta?.name ?? "taxonomy-gbf___ar",
    path: indexrSB8j5cgCBMeta?.path ?? "/ar/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    alias: indexrSB8j5cgCBMeta?.alias || [],
    redirect: indexrSB8j5cgCBMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSB8j5cgCBMeta?.name ?? "taxonomy-gbf___en___default",
    path: indexrSB8j5cgCBMeta?.path ?? "/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    alias: indexrSB8j5cgCBMeta?.alias || [],
    redirect: indexrSB8j5cgCBMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSB8j5cgCBMeta?.name ?? "taxonomy-gbf___en",
    path: indexrSB8j5cgCBMeta?.path ?? "/en/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    alias: indexrSB8j5cgCBMeta?.alias || [],
    redirect: indexrSB8j5cgCBMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSB8j5cgCBMeta?.name ?? "taxonomy-gbf___fr",
    path: indexrSB8j5cgCBMeta?.path ?? "/fr/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    alias: indexrSB8j5cgCBMeta?.alias || [],
    redirect: indexrSB8j5cgCBMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSB8j5cgCBMeta?.name ?? "taxonomy-gbf___es",
    path: indexrSB8j5cgCBMeta?.path ?? "/es/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    alias: indexrSB8j5cgCBMeta?.alias || [],
    redirect: indexrSB8j5cgCBMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSB8j5cgCBMeta?.name ?? "taxonomy-gbf___ru",
    path: indexrSB8j5cgCBMeta?.path ?? "/ru/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    alias: indexrSB8j5cgCBMeta?.alias || [],
    redirect: indexrSB8j5cgCBMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSB8j5cgCBMeta?.name ?? "taxonomy-gbf___zh",
    path: indexrSB8j5cgCBMeta?.path ?? "/zh/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    alias: indexrSB8j5cgCBMeta?.alias || [],
    redirect: indexrSB8j5cgCBMeta?.redirect,
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue").then(m => m.default || m)
  },
  {
    name: email_45alertsANwd1IQgThMeta?.name ?? "users-email-alerts___ar",
    path: email_45alertsANwd1IQgThMeta?.path ?? "/ar/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    alias: email_45alertsANwd1IQgThMeta?.alias || [],
    redirect: email_45alertsANwd1IQgThMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/email-alerts.vue").then(m => m.default || m)
  },
  {
    name: email_45alertsANwd1IQgThMeta?.name ?? "users-email-alerts___en___default",
    path: email_45alertsANwd1IQgThMeta?.path ?? "/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    alias: email_45alertsANwd1IQgThMeta?.alias || [],
    redirect: email_45alertsANwd1IQgThMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/email-alerts.vue").then(m => m.default || m)
  },
  {
    name: email_45alertsANwd1IQgThMeta?.name ?? "users-email-alerts___en",
    path: email_45alertsANwd1IQgThMeta?.path ?? "/en/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    alias: email_45alertsANwd1IQgThMeta?.alias || [],
    redirect: email_45alertsANwd1IQgThMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/email-alerts.vue").then(m => m.default || m)
  },
  {
    name: email_45alertsANwd1IQgThMeta?.name ?? "users-email-alerts___fr",
    path: email_45alertsANwd1IQgThMeta?.path ?? "/fr/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    alias: email_45alertsANwd1IQgThMeta?.alias || [],
    redirect: email_45alertsANwd1IQgThMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/email-alerts.vue").then(m => m.default || m)
  },
  {
    name: email_45alertsANwd1IQgThMeta?.name ?? "users-email-alerts___es",
    path: email_45alertsANwd1IQgThMeta?.path ?? "/es/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    alias: email_45alertsANwd1IQgThMeta?.alias || [],
    redirect: email_45alertsANwd1IQgThMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/email-alerts.vue").then(m => m.default || m)
  },
  {
    name: email_45alertsANwd1IQgThMeta?.name ?? "users-email-alerts___ru",
    path: email_45alertsANwd1IQgThMeta?.path ?? "/ru/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    alias: email_45alertsANwd1IQgThMeta?.alias || [],
    redirect: email_45alertsANwd1IQgThMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/email-alerts.vue").then(m => m.default || m)
  },
  {
    name: email_45alertsANwd1IQgThMeta?.name ?? "users-email-alerts___zh",
    path: email_45alertsANwd1IQgThMeta?.path ?? "/zh/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    alias: email_45alertsANwd1IQgThMeta?.alias || [],
    redirect: email_45alertsANwd1IQgThMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/email-alerts.vue").then(m => m.default || m)
  },
  {
    name: settingsI2eB0HcpFMeta?.name ?? "users-setting___ar",
    path: settingsI2eB0HcpFMeta?.path ?? "/ar/users/setting",
    meta: settingsI2eB0HcpFMeta || {},
    alias: settingsI2eB0HcpFMeta?.alias || [],
    redirect: settingsI2eB0HcpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/setting.vue").then(m => m.default || m)
  },
  {
    name: settingsI2eB0HcpFMeta?.name ?? "users-setting___en___default",
    path: settingsI2eB0HcpFMeta?.path ?? "/users/setting",
    meta: settingsI2eB0HcpFMeta || {},
    alias: settingsI2eB0HcpFMeta?.alias || [],
    redirect: settingsI2eB0HcpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/setting.vue").then(m => m.default || m)
  },
  {
    name: settingsI2eB0HcpFMeta?.name ?? "users-setting___en",
    path: settingsI2eB0HcpFMeta?.path ?? "/en/users/setting",
    meta: settingsI2eB0HcpFMeta || {},
    alias: settingsI2eB0HcpFMeta?.alias || [],
    redirect: settingsI2eB0HcpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/setting.vue").then(m => m.default || m)
  },
  {
    name: settingsI2eB0HcpFMeta?.name ?? "users-setting___fr",
    path: settingsI2eB0HcpFMeta?.path ?? "/fr/users/setting",
    meta: settingsI2eB0HcpFMeta || {},
    alias: settingsI2eB0HcpFMeta?.alias || [],
    redirect: settingsI2eB0HcpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/setting.vue").then(m => m.default || m)
  },
  {
    name: settingsI2eB0HcpFMeta?.name ?? "users-setting___es",
    path: settingsI2eB0HcpFMeta?.path ?? "/es/users/setting",
    meta: settingsI2eB0HcpFMeta || {},
    alias: settingsI2eB0HcpFMeta?.alias || [],
    redirect: settingsI2eB0HcpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/setting.vue").then(m => m.default || m)
  },
  {
    name: settingsI2eB0HcpFMeta?.name ?? "users-setting___ru",
    path: settingsI2eB0HcpFMeta?.path ?? "/ru/users/setting",
    meta: settingsI2eB0HcpFMeta || {},
    alias: settingsI2eB0HcpFMeta?.alias || [],
    redirect: settingsI2eB0HcpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/setting.vue").then(m => m.default || m)
  },
  {
    name: settingsI2eB0HcpFMeta?.name ?? "users-setting___zh",
    path: settingsI2eB0HcpFMeta?.path ?? "/zh/users/setting",
    meta: settingsI2eB0HcpFMeta || {},
    alias: settingsI2eB0HcpFMeta?.alias || [],
    redirect: settingsI2eB0HcpFMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/setting.vue").then(m => m.default || m)
  },
  {
    name: _91_91identifier_93_93g2w7yGOZ1pMeta?.name ?? "workflows-batches-batchId-identifier___ar",
    path: _91_91identifier_93_93g2w7yGOZ1pMeta?.path ?? "/ar/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    alias: _91_91identifier_93_93g2w7yGOZ1pMeta?.alias || [],
    redirect: _91_91identifier_93_93g2w7yGOZ1pMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue").then(m => m.default || m)
  },
  {
    name: _91_91identifier_93_93g2w7yGOZ1pMeta?.name ?? "workflows-batches-batchId-identifier___en___default",
    path: _91_91identifier_93_93g2w7yGOZ1pMeta?.path ?? "/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    alias: _91_91identifier_93_93g2w7yGOZ1pMeta?.alias || [],
    redirect: _91_91identifier_93_93g2w7yGOZ1pMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue").then(m => m.default || m)
  },
  {
    name: _91_91identifier_93_93g2w7yGOZ1pMeta?.name ?? "workflows-batches-batchId-identifier___en",
    path: _91_91identifier_93_93g2w7yGOZ1pMeta?.path ?? "/en/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    alias: _91_91identifier_93_93g2w7yGOZ1pMeta?.alias || [],
    redirect: _91_91identifier_93_93g2w7yGOZ1pMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue").then(m => m.default || m)
  },
  {
    name: _91_91identifier_93_93g2w7yGOZ1pMeta?.name ?? "workflows-batches-batchId-identifier___fr",
    path: _91_91identifier_93_93g2w7yGOZ1pMeta?.path ?? "/fr/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    alias: _91_91identifier_93_93g2w7yGOZ1pMeta?.alias || [],
    redirect: _91_91identifier_93_93g2w7yGOZ1pMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue").then(m => m.default || m)
  },
  {
    name: _91_91identifier_93_93g2w7yGOZ1pMeta?.name ?? "workflows-batches-batchId-identifier___es",
    path: _91_91identifier_93_93g2w7yGOZ1pMeta?.path ?? "/es/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    alias: _91_91identifier_93_93g2w7yGOZ1pMeta?.alias || [],
    redirect: _91_91identifier_93_93g2w7yGOZ1pMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue").then(m => m.default || m)
  },
  {
    name: _91_91identifier_93_93g2w7yGOZ1pMeta?.name ?? "workflows-batches-batchId-identifier___ru",
    path: _91_91identifier_93_93g2w7yGOZ1pMeta?.path ?? "/ru/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    alias: _91_91identifier_93_93g2w7yGOZ1pMeta?.alias || [],
    redirect: _91_91identifier_93_93g2w7yGOZ1pMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue").then(m => m.default || m)
  },
  {
    name: _91_91identifier_93_93g2w7yGOZ1pMeta?.name ?? "workflows-batches-batchId-identifier___zh",
    path: _91_91identifier_93_93g2w7yGOZ1pMeta?.path ?? "/zh/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    alias: _91_91identifier_93_93g2w7yGOZ1pMeta?.alias || [],
    redirect: _91_91identifier_93_93g2w7yGOZ1pMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue").then(m => m.default || m)
  },
  {
    name: requestseemQCdsGWcMeta?.name ?? "workflows-requests___ar",
    path: requestseemQCdsGWcMeta?.path ?? "/ar/workflows/requests",
    meta: requestseemQCdsGWcMeta || {},
    alias: requestseemQCdsGWcMeta?.alias || [],
    redirect: requestseemQCdsGWcMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/requests.vue").then(m => m.default || m)
  },
  {
    name: requestseemQCdsGWcMeta?.name ?? "workflows-requests___en___default",
    path: requestseemQCdsGWcMeta?.path ?? "/workflows/requests",
    meta: requestseemQCdsGWcMeta || {},
    alias: requestseemQCdsGWcMeta?.alias || [],
    redirect: requestseemQCdsGWcMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/requests.vue").then(m => m.default || m)
  },
  {
    name: requestseemQCdsGWcMeta?.name ?? "workflows-requests___en",
    path: requestseemQCdsGWcMeta?.path ?? "/en/workflows/requests",
    meta: requestseemQCdsGWcMeta || {},
    alias: requestseemQCdsGWcMeta?.alias || [],
    redirect: requestseemQCdsGWcMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/requests.vue").then(m => m.default || m)
  },
  {
    name: requestseemQCdsGWcMeta?.name ?? "workflows-requests___fr",
    path: requestseemQCdsGWcMeta?.path ?? "/fr/workflows/requests",
    meta: requestseemQCdsGWcMeta || {},
    alias: requestseemQCdsGWcMeta?.alias || [],
    redirect: requestseemQCdsGWcMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/requests.vue").then(m => m.default || m)
  },
  {
    name: requestseemQCdsGWcMeta?.name ?? "workflows-requests___es",
    path: requestseemQCdsGWcMeta?.path ?? "/es/workflows/requests",
    meta: requestseemQCdsGWcMeta || {},
    alias: requestseemQCdsGWcMeta?.alias || [],
    redirect: requestseemQCdsGWcMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/requests.vue").then(m => m.default || m)
  },
  {
    name: requestseemQCdsGWcMeta?.name ?? "workflows-requests___ru",
    path: requestseemQCdsGWcMeta?.path ?? "/ru/workflows/requests",
    meta: requestseemQCdsGWcMeta || {},
    alias: requestseemQCdsGWcMeta?.alias || [],
    redirect: requestseemQCdsGWcMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/requests.vue").then(m => m.default || m)
  },
  {
    name: requestseemQCdsGWcMeta?.name ?? "workflows-requests___zh",
    path: requestseemQCdsGWcMeta?.path ?? "/zh/workflows/requests",
    meta: requestseemQCdsGWcMeta || {},
    alias: requestseemQCdsGWcMeta?.alias || [],
    redirect: requestseemQCdsGWcMeta?.redirect,
    component: () => import("/usr/src/app/pages/workflows/requests.vue").then(m => m.default || m)
  }
]